import React, {Component} from "react";
import {Modal, OverlayTrigger} from "react-bootstrap";
import './_style.scss';
import PropTypes from "prop-types";
import Api from "../../../services/ApiService";
import UserComment from "../../molecules/UserComment/UserComment";
import PageLoaderSpinner from "../../atoms/PageLoaderSpinner/PageLoderSpinner";

export default class MilestoneCompletionModal extends Component {
    static propTypes = {
        close: PropTypes.func.isRequired,
        show: PropTypes.bool.isRequired,
    };

    close() {
        const {
            milestone,
            resetMilestone,
            close,
        } = this.props;
        Api.get(`milestones/${milestone.id}`).then((response) => {
            resetMilestone(response.data);
            close();
        });

    }

    save(ev)
    {
        ev.preventDefault();
        this.markAsCompleted();
    }

    async markAsCompleted()
    {
        const {
            milestone,
            resetMilestone,
            close,
        } = this.props;
        const {data} = await Api.patch(`milestones/${milestone.id}`, {percent_done: 100});
        resetMilestone(data);
        close();
    }

    render() {
        const {
            show,
            milestone
        } = this.props;

        return (
            <Modal
                dialogClassName="milestone-completion-modal"
                show={show}
                onHide={() => this.close()}
                centered
                enforceFocus={false}
            >
                <Modal.Body>
                    <>
                        Confirm completion of milestone '{milestone.description}'
                        <div>
                            <button onClick={(ev) => this.save(ev)}><i className="zwicon-checkmark" /></button>
                            <button onClick={(ev) => this.close(ev)}><i className="zwicon-close" /></button>
                        </div>
                    </>
                </Modal.Body>
            </Modal>
        )
    }
}
