import {
    ACT_USR_ADD_201,
    ACT_USR_ADD_INIT,
    ACT_USR_FETCH_200,
    ACT_USR_FETCH_INIT
} from "../constants/actions";

export const addUserAction  = (
    lastName,
    firstName,
    email,
    password,
    profileImageId,
) => ({
    type: ACT_USR_ADD_INIT,
    lastName,
    firstName,
    email,
    password,
    profileImageId,
});

export const addUserSuccessAction = (user) => ({type: ACT_USR_ADD_201, user});

export const fetchUsersAction = () => ({type: ACT_USR_FETCH_INIT});

export const fetchUsersSuccessAction = (users) => ({type: ACT_USR_FETCH_200, users});
