import {takeEvery, takeLatest, put} from 'redux-saga/effects';
import {ACT_AUTH_TOKEN_SUCCESS, ACT_VEN_ADD_INIT, ACT_VEN_FETCH_INIT} from "../constants/actions";
import Api from "../services/ApiService";
import {addVentureSuccessAction, fetchVenturesSuccessAction} from "../actions/venturesActions";


function* addVenture(action) {
    const {status, data} = yield Api.post('ventures', {name: action.ventureName});
    if (status === 201) {
        let venture = data;
        venture.new = true;
        yield put(addVentureSuccessAction(venture));
    } else {
        console.log("Add venture is not a 201", status);
    }
}

function* fetchVentures(action) {
    const {status, data} = yield Api.get('ventures');
    if(status === 200) {
        yield put(fetchVenturesSuccessAction(data));
    } else {
        console.log("List ventures is not a 200", status);
    }
}

export default function* venturesSagas (){
    yield takeEvery(ACT_VEN_FETCH_INIT, fetchVentures)
    yield takeEvery(ACT_VEN_ADD_INIT, addVenture)
    yield takeLatest(ACT_AUTH_TOKEN_SUCCESS, fetchVentures);
}

