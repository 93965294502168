import React, {Component} from 'react';
import {connect} from 'react-redux';
import Draggable from 'react-draggable';
import './_styles.scss';
import ApiService from "../../../services/ApiService";
import {profileImageUrl} from "../../../helpers/urls";
import {OverlayTrigger, Tooltip} from "react-bootstrap";
import {updateProgressbarWidthAction} from "../../../actions/milestonesActions";

class MilestoneProgress extends Component
{
    constructor(props) {
        super(props);
        this.progressBarRef = React.createRef();

        const offsetPos = this.calcPos(props.milestone.percent_done, this.props.progressWidth);
        const percentage = this.calcPercent(offsetPos, this.props.progressWidth);
        this.state = {
            percentage,
            offsetPos,
            offsetPercent: 0,
            userChange: 0,
        };
    }

    calcPos(percent, progressWidth) {
        return (percent / 100) * progressWidth;
    }

    calcPercent(pos, progressWidth) {
        const progressPercent = (pos / progressWidth) * 100;
        let roundPercent;
        if (progressPercent > 51) {
            roundPercent = Math.ceil(progressPercent);
        } else {
            roundPercent = Math.floor(progressPercent);
        }
        if (roundPercent > 100) {
            roundPercent = 100;
        }
        if (roundPercent < 0) {
            roundPercent = 0;
        }
        return roundPercent;
    }

    changeProgress(ev, data) {
        const { offsetPos } = this.state;
        const { progressWidth } = this.props;
        const percentage = this.calcPercent(data.x + offsetPos, progressWidth);
        this.setState({percentage, userChange: data.x});
    }

    reEvalWithoutChange(newWidth) {
        const {offsetPos, percentage, userChange} = this.state;
        const newOffset = this.calcPos(percentage, newWidth);
        this.setState({
            offsetPos: newOffset - userChange,
        });
    }

    reEvalOffset(prevPercent, newPercent) {
        const { offsetPos, percentage } = this.state;
        const { progressWidth } = this.props;

        if (newPercent === percentage) {
            return; // component already aware
        }
        const change = newPercent - prevPercent;
        const newOffset = this.calcPos(change, progressWidth);
        this.setState({
            percentage: newPercent,
            offsetPos: offsetPos + newOffset,
        });

    }

    handleResize() {
        if (this.progressBarRef.current === null) {
            return;
        }
        if (this.handleResizeTimeout) {
           clearTimeout(this.handleResizeTimeout);
        }
        this.handleResizeTimeout = setTimeout(() => {
            const { updateProgressbarWidth } = this.props;
            updateProgressbarWidth(this.progressBarRef.current.getBoundingClientRect().width);
        }, 100);
    }


    initSize() {
        const { updateProgressbarWidth } = this.props;
        updateProgressbarWidth(this.progressBarRef.current.getBoundingClientRect().width);
    }

    componentDidMount() {
        window.onresize = () => this.handleResize();
        const { progressWidthInit } = this.props;
        if (!progressWidthInit) {
            this.initSize();
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { milestone, progressWidth } = this.props;
        if (prevProps.milestone !== milestone)
        {
           this.reEvalOffset(prevProps.milestone.percent_done, milestone.percent_done);
        }
        if (prevProps.progressWidth !== progressWidth) {
            this.reEvalWithoutChange(progressWidth);
        }
    }

    saveProgress(ev, draggableData)
    {
        const { offsetPos } = this.state;
        const { milestone, resetMilestone, progressWidth } = this.props;
        const percentage = this.calcPercent(draggableData.x + offsetPos, progressWidth);
        if (percentage === 100 && milestone.percent_done !== 100) {
            this.props.openCompletionModal();
            const undoChange = 100 - milestone.percent_done;
            const reduceOffset = this.calcPos(undoChange, progressWidth);
            this.setState({
                percentage: milestone.percent_done,
                offsetPos: offsetPos - reduceOffset,
            });
            return;
        }

        ApiService.patch('milestones/' + milestone.id, { percent_done: percentage }).then((response) => {
            resetMilestone(response.data);
        });
    }

    render()
    {
        const { percentage, offsetPos } = this.state;
        const { milestone, user, progressWidth } = this.props;
        const canUpdate = user.is_admin || (user.id === milestone.assigned_to_id && percentage !== 100);
        const blockerText = percentage === 100 ? 'Completed milestones are locked' : 'Only milestone owner can transfer ownership';
        const swapIcon = canUpdate
            ?   <div className="milestone-swap" onClick={() => this.props.openReassignBar()}>
                <img src="/img/users.png" width="25"/>
            </div>
            : <OverlayTrigger
                placement="left"
                delay={{ show: 250, hide: 400 }}
                overlay={
                    <Tooltip id="validation-blocker-tooltip">
                        <div>
                            {blockerText}
                        </div>
                    </Tooltip>
                }
            >
                <div className="milestone-swap">
                    <img src="/img/users.png" width="25"/>
                </div>
            </OverlayTrigger>;

        const scale = 0.9 + (percentage/300);
        return <div className="progress-container">
            <div className="milestone-progress-bar" ref={this.progressBarRef} />
            <Draggable
                bounds="parent"
                axis="x"
                enableUserSelectHack="true"
                disabled={!canUpdate}
                defaultClassName="milestone-user-draggable"
                onDrag={(ev, data) => this.changeProgress(ev, data)}
                onStop={(ev, data) => this.saveProgress(ev, data)}
            >
                <div className="milestone-user-img-container"
                     style={{left: offsetPos}}
                >
                    {
                        percentage === 100
                            ? <img className="completion-checkmark" src="/img/checkmark-v2.png" />
                            : null
                    }
                    <div className="milestones-extra-controls">
                        <div className="milestone-percent-indicator">{percentage}%</div>

                        { swapIcon }
                        <div className="milestone-chat" onClick={()  => this.props.openCommentsModal()}>
                            <img src="/img/discussion.png" width="25"/>
                        </div>
                    </div>
                    <img className="milestone-user-img" src={profileImageUrl(milestone.assigned_to)} alt="" style={{transform: `scale(${scale})`}} />
                </div>
            </Draggable>
        </div>
    }
}

const mapStateToProps = state => ({
    user: state.auth.user,
    progressWidth: state.milestones.progressWidth,
    progressWidthInit: state.milestones.progressWidthInit,
});

const mapDispatchToProps = dispatch => ({
    updateProgressbarWidth: newWidth => dispatch(updateProgressbarWidthAction(newWidth))
});

export default connect(mapStateToProps, mapDispatchToProps)(MilestoneProgress);
