import React, {Component} from "react";
import {Modal, OverlayTrigger, Tooltip} from "react-bootstrap";
import {connect} from 'react-redux';
import './_style.scss';
import PropTypes from "prop-types";
import NewUserUpload from "../../molecules/NewUserUpload/NewUserUpload";
import {addUserAction, addUserSuccessAction} from "../../../actions/usersActions";
import Api from "../../../services/ApiService";
import PageLoaderSpinner from "../../atoms/PageLoaderSpinner/PageLoderSpinner";

class AddUserModel extends Component {
    static propTypes = {
        close: PropTypes.func.isRequired,
        show: PropTypes.bool.isRequired,
    };

    constructor(props) {
        super(props);
        this.state = {
            lastName: '',
            firstName: '',
            email: '',
            password1: '',
            password2: '',
            profileImageId: null,
            blocked: true,
            blocker: 'Please fill all fields',
        };
    }

    updateBlockers()
    {
        const {
            lastName,
            firstName,
            email,
            password1,
            password2,
        } = this.state;

        if (password1 !== password2) {
            this.setState({blocked: true, blocker: 'Password confirmation not matching password'});
        } else if (password1.length < 6) {
            this.setState({blocked: true, blocker: 'Password too short'});
        } else if (email.length < 4) {
            this.setState({blocked: true, blocker: 'Please enter a valid email'});
        } else if ((lastName + lastName).length < 2 ) {
            this.setState({blocked: true, blocker: 'Please fill user first name & last name'});
        } else {
            this.setState({blocked: false, blocker: ''});
        }
    }

    close() {
        this.setState({
            lastName: '',
            firstName: '',
            email: '',
            password1: '',
            password2: '',
            profileImageId: null,
            isPending: false,
            error: '',
            blocked: true,
        });
        this.props.close();
    }

    async save(ev) {
        const {
            lastName,
            firstName,
            email,
            password1,
            blocked,
            profileImageId,
        } = this.state;
        const {
            userAdded,
        } = this.props;
        ev.preventDefault();
        if (blocked) {
            return;
        }
        try {
            this.setState({
                isPending: true,
                error: '',
            });
            const {data, status} = await Api.post('users', {
                last_name: lastName,
                first_name: firstName,
                email,
                password: password1,
                profile_image_id: profileImageId
            }, false);
            if (status === 201) {
                userAdded(data);
                this.setState({
                    isPending: false,
                });
                this.close();
            } else {
                this.setState({
                    isPending: false,
                    error: data.message,
                });
            }

        } catch (ev) {
            this.setState({
                isPending: false,
                error: 'Error creating user'
            });
        }
    }

    imageUpdate(imageId) {
        console.log('got new image id', imageId);
        this.setState({profileImageId : imageId});
    }

    inputUpdate(fieldName, value) {
        const newState = {error: ''};
        newState[fieldName] = value;
        this.setState(
            newState,
            () => this.updateBlockers()
        );
    }

    render() {
        const {
            lastName,
            firstName,
            email,
            password1,
            password2,
            blocked,
            blocker,
            error,
            isPending,
        } = this.state;
        const {
            show,
        } = this.props;

        let submitButton = blocked
            ? <OverlayTrigger
                    placement="bottom"
                    delay={{ show: 250, hide: 400 }}
                    overlay={
                        <Tooltip id="validation-blocker-tooltip">
                            <div>
                                {blocker}
                            </div>
                        </Tooltip>
                    }
                >
                    <div>
                        <button onClick={(ev) => ev.preventDefault()}><i className="zwicon-checkmark" /></button>
                    </div>
                </OverlayTrigger>
            : <button type="submit"><i className="zwicon-checkmark" /></button>;
        if (isPending) {
            submitButton = <PageLoaderSpinner />
        }
        return (
            <Modal
                dialogClassName="add-user-modal"
                show={show}
                onHide={() => this.close()}
                centered
                enforceFocus={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Add new user!</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form onSubmit={(ev) => this.save(ev)} autoComplete={false}>
                      <table border="0" width="100%">
                        <tr>
                            <td width="1">
                                <NewUserUpload onUploadDone={imageId => this.imageUpdate(imageId)}/>
                            </td>
                            <td>
                                <div className="form-group">
                                    <input
                                        name="new_last_name"
                                        type="text"
                                        className="form-control"
                                        placeholder="Last name"
                                        value={lastName}
                                        autoComplete="new_last_name"
                                        onChange={(ev) => this.inputUpdate('lastName', ev.target.value)}
                                    />
                                </div>

                                <div className="form-group">
                                    <input
                                        name="new_first_name"
                                        type="text"
                                        className="form-control"
                                        placeholder="First name"
                                        value={firstName}
                                        autoComplete="new_first_name"
                                        onChange={(ev) => this.inputUpdate('firstName', ev.target.value)}
                                    />
                                </div>

                                <div className="form-group">
                                    <input
                                        name="new_email"
                                        type="email"
                                        className="form-control"
                                        placeholder="Email Address"
                                        value={email}
                                        autoComplete="new_email"
                                        onChange={(ev) => this.inputUpdate('email', ev.target.value)}
                                    />
                                </div>

                                <div className="form-group">
                                    <input
                                        name="password1"
                                        type="password"
                                        className="form-control"
                                        placeholder="Password"
                                        min="6"
                                        value={password1}
                                        autoComplete="password1"
                                        onChange={(ev) => this.inputUpdate('password1', ev.target.value)}
                                    />
                                </div>

                                <div className="form-group">
                                    <input
                                        name="password2"
                                        type="password"
                                        className="form-control"
                                        placeholder="Confirm password"
                                        value={password2}
                                        autoComplete="password2"
                                        onChange={(ev) => this.inputUpdate('password2', ev.target.value)}
                                    />
                                </div>
                            </td>
                        </tr>
                    </table>
                    <br />
                    <span className="error-message">{error}</span>
                    <br />
                    { submitButton }
                    </form>
                </Modal.Body>
            </Modal>
        )
    }
}

const mapPropsToState = state => ({
    isAddingUser: state.users.isAddingUser,
});

const mapDispatchToProps = dispatch => ({
    userAdded: (user) => dispatch(addUserSuccessAction(user)),
});

export default connect(mapPropsToState, mapDispatchToProps)(AddUserModel);
