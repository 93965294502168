import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Dropdown} from 'react-bootstrap';
import {ThreeDotsDropdown} from '../../atoms/ThreeDotsDropdown/ThreeDotsDropdown';
import {attemptLoginAction} from "../../../actions/authActions";
import FullPageLoader from "../../organisms/FullPageLoader/FullPageLoader";

class Login extends Component {

    constructor() {
        super();
        this.state = {
            active: 'login',
            username: '',
            password: '',
        };
    }

    login(ev) {
        ev.preventDefault();
        const {username,password} = this.state;
        const {attemptLogin} = this.props;
        attemptLogin(username, password);
    }

    render() {
        const {active, username, password} = this.state;
        const dropdown = (
            <div className="actions actions--inverse login__block__actions">
                <Dropdown>
                    <Dropdown.Toggle as={ThreeDotsDropdown} className="dropdown"/>
                    <Dropdown.Menu>
                        {
                            active === 'login'
                            ? <Dropdown.Item onClick={() => this.setState({active: 'forgot_password'})}>Forgot password</Dropdown.Item>
                            : <Dropdown.Item onClick={() => this.setState({active: 'login'})}>Back to login</Dropdown.Item>
                        }
                    </Dropdown.Menu>
                </Dropdown>
            </div>
        );
        return (
            <div className="login">
                <FullPageLoader />

                <div className={ active === 'login' ? 'login__block active' : 'login__block'}>
                    <div className="login__block__header">
                        <i className="zwicon-user-circle" />
                        Hi there! Please Sign in
                        {/*{ dropdown }*/}
                    </div>
                    <form onSubmit={(ev) => this.login(ev)}>
                        <div className="login__block__body">
                            <div className="form-group">
                                <input
                                    name="username"
                                    type="text"
                                    className="form-control text-center"
                                    placeholder="Email Address"
                                    value={username}
                                    onChange={(ev) => this.setState({username: ev.target.value})}
                                />
                            </div>

                            <div className="form-group">
                                <input
                                    name="password"
                                    type="password"
                                    className="form-control text-center"
                                    placeholder="Password"
                                    value={password}
                                    onChange={(ev) => this.setState({password: ev.target.value})}
                                />
                            </div>

                            <button className="btn btn-theme btn--icon btn-login" type="submit"><i className="zwicon-checkmark"/></button>
                        </div>
                    </form>
                </div>

                <div className={ active === 'forgot_password' ? 'login__block active' : 'login__block'}>
                    <div className="login__block__header">
                        <i className="zwicon-user-circle"/>
                        Forgot Password?

                        { dropdown }
                    </div>

                    <div className="login__block__body">
                        <p className="mb-5">Lorem ipsum dolor fringilla enim feugiat commodo sed ac lacus.</p>

                        <div className="form-group">
                            <input type="text" className="form-control text-center" placeholder="Email Address" />
                        </div>

                        <a href="index.html" className="btn btn-theme btn--icon"><i className="zwicon-checkmark"/></a>
                    </div>
                </div>
            </div>
        );
    }
}

const mapDispatchToProps = dispatch => ({
   attemptLogin: (username, password) => dispatch(attemptLoginAction(username, password)),
});

export default connect(null, mapDispatchToProps)(Login);
