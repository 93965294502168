import React, {Component} from 'react';
import {connect} from 'react-redux';
import './_styles.scss';
import {profileImageUrl} from "../../../helpers/urls";
import {quickMilestoneFilterAction} from "../../../actions/milestonesActions";
import UserOptionLabel from "../UserOptionLabel/UserOptionLabel";

class StatsCardUser extends Component {
    render() {
        const {
            user,
            selectedMilestoneCategories,
            selectedVentures,
            selectedTag,
            quickFilter,
        } = this.props;

        if( user === undefined) {
            return <div className="stats-card-user">
                <img className="round-img" src="" alt="" />
            </div>
        }

        const selectedAssignedTos = [{ value: user.id, label: UserOptionLabel(user)}];
        return <div
            className="stats-card-user"
            onClick={() => quickFilter(selectedAssignedTos, selectedMilestoneCategories, selectedVentures, selectedTag)}
        >
            <img className="round-img" src={profileImageUrl(user)} alt="" />
        </div>;
    }
}

const mapDispatchToProps = dispatch => ({
    quickFilter: (
        selectedAssignedTos,
        selectedMilestoneCategories,
        selectedVentures,
        selectedTag
    ) => dispatch(
        quickMilestoneFilterAction(
            selectedAssignedTos,
            selectedMilestoneCategories,
            selectedVentures,
            selectedTag
        )
    ),
});

export default connect(null, mapDispatchToProps)(StatsCardUser);
