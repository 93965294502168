import React, {Component} from "react";
import FullPageLoader from "../../organisms/FullPageLoader/FullPageLoader";
import PageHeader from "../../organisms/PageHeader/PageHeader";
import Sidebar from "../../organisms/Sidebar/Sidebar";

export default class OnePageLayout extends Component {
    render() {
        const {children} = this.props;
        return (
            <div className="main-container">
                <FullPageLoader />
                <PageHeader />
                {/*<Sidebar />*/}
                <div className="page-content">
                    {children}
                </div>
            </div>
        );
    }
}
