import React, {Component} from 'react';
import AddButton from "../../atoms/AddButton/AddButtons";
import AddMilestoneModal from "../AddMilestoneModal/AddMilestoneModal";

export default class AddMilestoneButton extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showAddModal: false
        };
    }

    render() {
        const { showAddModal } = this.state;
        return <>
            <AddMilestoneModal show={showAddModal} close={() => this.setState({showAddModal: false})}/>
            <button className="milestones-add-button" onClick={() => this.setState({showAddModal: true})}>
                <AddButton />
            </button>
        </>;
    }
}
