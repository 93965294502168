import { createStore, applyMiddleware } from 'redux'
import createSagaMiddleware from 'redux-saga'
import reducer from './reducers';
import {logSaga} from './sagas/logSaga';
import {rootSaga} from './sagas';

// create the saga middleware
const sagaMiddleware = createSagaMiddleware();

// mount it on the Store
const store = createStore(
    reducer,
    applyMiddleware(sagaMiddleware)
);

if (window.config.debug) {
    sagaMiddleware.run(logSaga);
}
sagaMiddleware.run(rootSaga);

export default store;
