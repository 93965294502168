import {
    ACT_MS_ADD_201,
    ACT_MS_ADD_INIT,
    ACT_MS_CLEAR_FILTERS,
    ACT_MS_FETCH_200,
    ACT_MS_FETCH_INIT, ACT_MS_FETCH_NEXT_200, ACT_MS_FETCH_NEXT_INIT,
    ACT_MS_FILTER_ASSIGNED_TO,
    ACT_MS_FILTER_CATEGORIES,
    ACT_MS_FILTER_TAG,
    ACT_MS_FILTER_VENTURES, ACT_MS_PROGRESSBAR_WIDTH,
    ACT_MS_QUICK_FILTER,
    ACT_MS_RESET,
    ACT_MS_SEARCH_UPDATE, ACT_MS_SORTING_UPDATE
} from "../constants/actions";

export const addMilestoneAction  = (
    ventureId,
    milestoneCategoryId,
    assignedTo,
    dueDate,
    description,

) => ({
    type: ACT_MS_ADD_INIT,
    ventureId,
    milestoneCategoryId,
    assignedTo,
    dueDate,
    description
});

export const updateProgressbarWidthAction = (progressWidth) => ({type: ACT_MS_PROGRESSBAR_WIDTH, progressWidth});
export const addMilestoneSuccessAction = (milestone) => ({type: ACT_MS_ADD_201, milestone});

export const milestonesSortingAction = (sortBy, sortDirection) => ({type: ACT_MS_SORTING_UPDATE, sortBy, sortDirection});
export const resetMilestonesAction = () => ({type: ACT_MS_RESET});
export const fetchMilestonesAction = () => ({type: ACT_MS_FETCH_INIT});
export const fetchNextMilestonesAction = () => ({type: ACT_MS_FETCH_NEXT_INIT});
export const fetchMilestonesSuccessAction = (response) => ({type: ACT_MS_FETCH_200, response});
export const fetchNextMilestonesSuccessAction = (response) => ({type: ACT_MS_FETCH_NEXT_200, response});

export const clearMilestoneFiltersAction = () => ({type: ACT_MS_CLEAR_FILTERS});
export const updateMilestoneSearchAction = (searchString) => ({type: ACT_MS_SEARCH_UPDATE, searchString});
export const updateMilestoneVentureFilterAction = (selectedVentures) => ({type: ACT_MS_FILTER_VENTURES, selectedVentures});
export const updateMilestoneTagFilterAction = (selectedTag) => ({type: ACT_MS_FILTER_TAG, selectedTag});
export const updateMilestoneCategoryFilterAction = (selectedMilestoneCategories) => ({type: ACT_MS_FILTER_CATEGORIES, selectedMilestoneCategories});
export const updateMilestoneAssignedToFilterAction = (selectedAssignedTos) => ({type: ACT_MS_FILTER_ASSIGNED_TO, selectedAssignedTos});
export const quickMilestoneFilterAction = (
    selectedAssignedTos,
    selectedMilestoneCategories,
    selectedVentures,
    selectedTag
) => ({
    type: ACT_MS_QUICK_FILTER,
    selectedAssignedTos,
    selectedMilestoneCategories,
    selectedVentures,
    selectedTag,
});
