import {all, fork } from 'redux-saga/effects';
import authSagas from './authSagas';
import categoriesSagas from "./categoriesSagas";
import venturesSagas from "./venturesSagas";
import usersSagas from "./usersSagas";
import milestonesSagas from "./milestonesSagas";

export function* rootSaga() {
    return yield all([
            fork(authSagas),
            fork(categoriesSagas),
            fork(usersSagas),
            fork(venturesSagas),
            fork(milestonesSagas),
        ]
    );
}
