import React from "react";


export const ThreeDotsDropdown = React.forwardRef(({ children, onClick }, ref) => (
    <a
        href="#"
        ref={ref}
        onClick={(e) => {
            e.preventDefault();
            onClick(e);
        }}
    >
        <i className="zwicon-more-h actions__item" />
        {children}
    </a>
));
