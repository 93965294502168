import React, {Component} from "react";
import {Modal} from "react-bootstrap";
import './_style.scss';
import PropTypes from "prop-types";
import Api from "../../../services/ApiService";
import UserComment from "../../molecules/UserComment/UserComment";
import PageLoaderSpinner from "../../atoms/PageLoaderSpinner/PageLoderSpinner";

export default class CommentsModal extends Component {
    static propTypes = {
        close: PropTypes.func.isRequired,
        show: PropTypes.bool.isRequired,
    };

    constructor(props) {
        super(props);
        this.state = {
            comments: [],
            page: 0,
            firstPageIsLoaded: false,
            isLastPage: true,
            hasPendingRequest: false,
            newCommentText: '',
            isPendingAddingComment: false,
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {firstPageIsLoaded, hasPendingRequest} = this.state;
        const { show } = this.props;
        if (!firstPageIsLoaded && !hasPendingRequest && show) {
            this.loadNextPage();
        }
    }

    close() {
        this.setState({
            comments: [],
            page: 0,
            firstPageIsLoaded: false,
            isLastPage: true,
            hasPendingRequest: false,
            newCommentText: '',
            isPendingAddingComment: false,
        });
        this.props.close();
    }

    async postComment()
    {
        const { milestone } = this.props;
        const { newCommentText } = this.state;
        this.setState({
            isPendingAddingComment: true,
        });
        const commentData = {
            message: newCommentText,
            milestone_id: milestone.id,
        };
        const {status, data} = await Api.post(`milestone_comments`, commentData);
        if(status === 201) {
            this.setState(
                (prevStat) => ({
                    comments: [data].concat(prevStat.comments),
                    isPendingAddingComment: false,
                    newCommentText: '',
                })
            );
        } else {
            this.setState({
                isPendingAddingComment: false,
            });
            setTimeout(() => this.loadNextPage(), 1500);
        }
    }

    async loadNextPage()
    {
        this.setState({
            hasPendingRequest: true,
        });
        const {milestone} = this.props;
        const {page} = this.state;
        const thisPage = page + 1;
        const {status, data} = await Api.get(`milestone_comments?milestone_id=${milestone.id}&page=${thisPage}`);
        if(status === 200) {
            this.setState(
                (prevStat) => ({
                    comments: prevStat.comments.concat(data.data),
                    firstPageIsLoaded: true,
                    page: thisPage,
                    hasPendingRequest: false,
                })
            );
        } else {
            this.setState({
                hasPendingRequest: false,
            });
            setTimeout(() => this.loadNextPage(), 1500);
        }
    }

    async updateAndClose(userId)
    {
        const {
            milestone,
        } = this.props;
        const {data} = await Api.patch(`milestones/${milestone.id}`, {assigned_to_id: userId})
        this.props.resetMilestone(data);
        this.close();
    }

    render() {
        const {
            firstPageIsLoaded,
            comments,
            isLastPage,
            newCommentText,
            isPendingAddingComment,
        } = this.state;

        const {
            show,
        } = this.props;

        const userComments =  comments.map((comment) => ( <UserComment comment={comment} />));
        return (
            <Modal
                dialogClassName="milestone-comments-modal"
                show={show}
                onHide={() => this.close()}
                centered
                enforceFocus={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Discussion</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <>
                        <div className="add-comment">
                            <textarea
                                className="new-comment-text"
                                placeholder="Add comment"
                                onChange={(ev) => this.setState({newCommentText: ev.target.value})}
                                disabled={isPendingAddingComment}
                                value={newCommentText}
                            />
                            <button
                                className="add-comment-button"
                                disabled={isPendingAddingComment}
                                onClick={() => this.postComment()}
                            >
                                Post
                            </button>
                        </div>
                        {
                            firstPageIsLoaded
                            ? userComments
                            : <PageLoaderSpinner />
                        }
                    </>
                </Modal.Body>
            </Modal>
        )
    }
}
