import React, {Component} from 'react';
import {connect} from 'react-redux';
import ContentLoader from "../../molecules/ContentLoader/ContentLoader";
import UserRow from "../UserRow/UserRow";
import {fetchUsersAction} from "../../../actions/usersActions";
import AddMilestoneModal from "../AddMilestoneModal/AddMilestoneModal";
import AddUserModel from "../AddUserModal/AddUserModel";
import AddButton from "../../atoms/AddButton/AddButtons";
import UserItem from "../UserItem/UserItem";

class UsersCard extends Component {

    constructor(props) {
        super(props);
        this.tableRef = React.createRef();
        this.state = {
            fixPlusSign: false,
            showAddModal: false,
        };
    }

    componentDidMount() {
        this.props.fetchUser();
        window.addEventListener('scroll', (ev) => this.handleScroll(ev));
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', (ev) => this.handleScroll(ev));
    }

    handleScroll(ev) {
        if (this.tableRef.current === null) {
            return;
        }
        const {top, bottom} = this.tableRef.current.getBoundingClientRect();
        if (top < 65 && bottom > 150) {
            this.setState({fixPlusSign: true});
        } else {
            this.setState({fixPlusSign: false});
        }
    }

    render() {
        const {fixPlusSign, showAddModal} = this.state;
        const {isLoadingUsers, usersList} = this.props;
        if (isLoadingUsers) {
            return <ContentLoader />
        }
        const usersRows = usersList.map((user) => (<UserItem key={user.id} user={user} />));
        return (
            <div className="card content-card" ref={this.tableRef}>
                <AddUserModel show={showAddModal} close={() => this.setState({showAddModal: false})}/>
                <div className="card-body">
                    <h4 className="card-title">Users</h4>
                    <h6 className="card-subtitle"> &nbsp; </h6>
                    <div className={fixPlusSign ? 'table-plus-icon fixed-plus': 'table-plus-icon'}>
                        {/*<QuickAddBox placeholder="Email" action={addVenture}/>*/}
                    </div>
                    <div className={fixPlusSign ? 'table-plus-icon fixed-plus': 'table-plus-icon'}>
                        <button onClick={() => this.setState({showAddModal: true})}>
                            <AddButton />
                        </button>
                    </div>
                    <table className="table table-striped mb-0">
                        <thead>
                            <tr>
                                <th width="1"> &nbsp; </th>
                                <th>First name</th>
                                <th>Last name</th>
                                <th>Email</th>
                                <th className="text-right">Total milestones</th>
                                <th>Type</th>
                                <th>Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            { usersRows }
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    usersList: state.users.usersList,
    isLoadingUsers: state.users.isLoadingUsers,
})

const mapDispatchToProps = dispatch => ({
    fetchUser: () => dispatch(fetchUsersAction()),
    // addUser: (ventureName) => dispatch(addVentureAction(ventureName)),
})

export default connect(mapStateToProps, mapDispatchToProps)(UsersCard);
