import VentureOptionLabel from "../components/atoms/VentureOptionLabel/VentureOptionLabel";
import CategoryOptionLabel from "../components/atoms/CategoryOptionLabel/CategoryOptionLabel";
import UserOptionLabel from "../components/atoms/UserOptionLabel/UserOptionLabel";


export const getMilestoneFilterOptions = (
    didLoadVentures,
    venturesList,
    didLoadCategories,
    categoriesList,
    didLoadUsers,
    usersList,
) => {
    const venturesOptions = didLoadVentures
        ? venturesList.map((venture) => ({value: venture.id, label: VentureOptionLabel(venture)}))
        : [ {value: null, label: 'Loading...'} ];
    const categoriesOptions = didLoadCategories
        ? categoriesList.map((category) => ({value: category.id, label: CategoryOptionLabel(category)}))
        :  [ {value: null, label: 'Loading...'} ];
    const usersOptions = didLoadUsers
        ? usersList.map((user) => ({value: user.id, label: UserOptionLabel(user)}))
        :  [ {value: null, label: 'Loading...'} ];
    return {venturesOptions, categoriesOptions, usersOptions};
};

