import React, {Component} from 'react';
import Api from "../../../services/ApiService";
import './_styles.scss'

export default class UploadableImage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isUploading: false,
            url: props.url
        };
        this.fileUploadInput = React.createRef();
    }

    selectFile() {
        this.fileUploadInput.current.click();
    }

    setImageId(imageId) {
        const {endpoint, fieldName, id} = this.props;
        const requestData = {};
        requestData[fieldName] = imageId
        Api.patch(`${endpoint}/${id}`, requestData);
    }

    uploadImage(ev) {
        this.setState({isUploading: true})
        Api.uploadOneFile('images', ev.target.files[0]).then((response) => {
            console.log(response);
            this.setState({isUploading: false});
            if (response.status === 201) {
                this.setImageId(response.data.id);
                this.setState({url: response.data.url});
            }
        });
    }

    render() {
        const {isUploading, url} = this.state;
        const uploading = isUploading ? 'uploading' : '';
        return <div className={'round-img-wrapper ' + uploading}>
            <input type="file" ref={this.fileUploadInput} style={{display: 'none'}} onChange={(ev) => this.uploadImage(ev)}/>
            {
                !isUploading ? <img className="round-img" src={url} alt="" onClick={() => this.selectFile()}/> : null
            }
        </div>
    }
}
