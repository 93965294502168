import {
    ACT_AUTH_LOGIN_ATTEMPT,
    ACT_AUTH_LOGIN_FAILED,
    ACT_AUTH_LOGIN_SUCCESS, ACT_AUTH_LOGOUT_INIT, ACT_AUTH_LOGOUT_SUCCESS,
    ACT_AUTH_TOKEN_CHECK, ACT_AUTH_TOKEN_FAIL, ACT_AUTH_TOKEN_SUCCESS
} from "../constants/actions";

export const loginFailedAction  = () => ({
    type: ACT_AUTH_LOGIN_FAILED
});

export const initLogoutAction  = () => ({
    type: ACT_AUTH_LOGOUT_INIT
});

export const logoutSuccessAction  = () => ({
    type: ACT_AUTH_LOGOUT_SUCCESS
});

export const loginSuccessAction = (accessToken, refreshToken, expiresIn) => ({
    type: ACT_AUTH_LOGIN_SUCCESS,
    accessToken,
    refreshToken,
    expiresIn,
});

export const attemptLoginAction = (username, password) => ({
    type: ACT_AUTH_LOGIN_ATTEMPT,
    username,
    password,
});

export const tokenCheckSuccessAction = (data) => ({
    type: ACT_AUTH_TOKEN_SUCCESS,
    user: data,
});

export const tokenCheckFailAction = () => ({
    type: ACT_AUTH_TOKEN_FAIL
});

export const tokenCheckAction = () => ({
    type: ACT_AUTH_TOKEN_CHECK
});
