import React from "react";
import PageLoaderSpinner from "../../atoms/PageLoaderSpinner/PageLoderSpinner";
import './_styles.scss';

const ContentLoader = () => (
    <div className="content-loader">
        <PageLoaderSpinner/>
    </div>
);

export default ContentLoader;
