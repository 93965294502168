import React from "react";

const TitleCard = (props) => {
    return <div className="row">
        <div className="col-12" >
            <div className="card content-card title-only">
                <div className="card-body">
                    <h4 className="card-title">{props.title}</h4>
                </div>
            </div>
        </div>
    </div>
}

export default TitleCard;
