import React, {Component} from 'react';
import './_styles.scss';

const MilestoneCommentsMobileButton = (props) => {
    return <div className="milestone-mobile-action-buttons" onClick={() => props.openCommentsModal()}>
            <img src="/img/discussion.png" width="35"/>
        </div>
}


export default MilestoneCommentsMobileButton;
