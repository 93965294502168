import React, {Component} from 'react';
import PropTypes from 'prop-types';
import './_styles.scss';
import Api from "../../../services/ApiService";

export default class LiveInput extends Component {
    static propTypes = {
        id: PropTypes.number.isRequired,
        fieldName: PropTypes.string.isRequired,
        endpoint: PropTypes.string.isRequired,
        placeholder: PropTypes.string,
        separateControls: PropTypes.bool,
    };

    static defaultProps = {
        placeholder: '',
        separateControls: false,
    };

    constructor(props) {
        super(props);
        const {value} = this.props;
        this.state = {
            value,
            lastSavedValue: value,
            editMode: false,
            updating: false,
        };
    }

    handleChange(ev) {
        this.setState({value: ev.target.value});
    }

    cancel() {
        this.setState((prevState) => ({value: prevState.lastSavedValue, editMode: false}));
    }

    async save() {
        const {endpoint, fieldName, id} = this.props;
        const {value, lastSavedValue} = this.state;
        const requestData = {};
        requestData[fieldName] = value
        this.setState({updating: true, editMode: false});
        try {
            await Api.patch(`${endpoint}/${id}`, requestData).then((response) => {
                if (response.status === 200) {
                    this.setState({updating: false, lastSavedValue: value})
                } else {
                    this.setState({updating: false, editMode: true, value: lastSavedValue});
                }
            });
        } catch  {
            this.setState({updating: false, editMode: true, value: lastSavedValue});
        }

    }

    render() {
        const {placeholder, separateControls} = this.props;
        const {value, editMode} = this.state;
        let classes = ['live-input', 'edit-mode'];
        if (separateControls) {
            classes.push('separate-controls');
        }
        if(editMode) {
            return <span className={classes.join(' ')}>
                <input className="form-control" placeholder={placeholder} value={value} onChange={(ev) => this.handleChange(ev)} />
                { separateControls ? <br /> : null}
                <button type="button" onClick={() => this.save()}><i className="zwicon-checkmark" /></button>
                <button type="button" onClick={() => this.cancel()}><i className="zwicon-close" /></button>
            </span>;
        }
        return <span className="live-input view-mode" onClick={() => this.setState({editMode: true})}>
            {value}
        </span>;
    }
}
