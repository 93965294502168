import {ACT_VEN_ADD_201, ACT_VEN_ADD_INIT, ACT_VEN_FETCH_200, ACT_VEN_FETCH_INIT} from "../constants/actions";

export const addVentureAction  = (ventureName) => ({
    type: ACT_VEN_ADD_INIT,
    ventureName
});

export const addVentureSuccessAction = (venture) => ({type: ACT_VEN_ADD_201, venture});

export const fetchVenturesAction = () => ({type: ACT_VEN_FETCH_INIT});
export const fetchVenturesSuccessAction = (ventures) => ({type: ACT_VEN_FETCH_200, ventures});
