import React, {Component} from 'react';
import {connect} from 'react-redux';
import './_styles.scss';
import ContentLoader from "../../molecules/ContentLoader/ContentLoader";
import {
    clearMilestoneFiltersAction,
    fetchMilestonesAction, fetchNextMilestonesAction, fetchNextMilestonesSuccessAction,
} from "../../../actions/milestonesActions";
import MilestoneItem from "../MilestoneItem/MilestoneItem";
import MilestoneFilters from "../MilestoneFilters/MilestoneFilters";
import Api from "../../../services/ApiService";
import MilestonesHeader from "../../atoms/MilestonesHeader/MilestonesHeader";

class RecentMilestonesCard extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            milestonesList: [],
        }
    }

    componentDidMount() {
        this.loadRecentMilestones();
    }

    loadRecentMilestones()
    {
        this.setState({isLoading: true, milestones: []});

        Api.get('milestones?per_page=5&sort_by=created_at&sort_direction=desc&filter_tag=FILTER_ONLY_NEW').then((res) => {
            const {status, data} = res;
            this.setState({
                milestonesList: data.data,
                isLoading: false
            });
        })
    }

    render() {
        const {
            isLoading,
            milestonesList,
        } = this.state;

        let milestonesItems = milestonesList.map((milestone) => {
            return <MilestoneItem milestone={milestone} key={milestone.id} />
        });
        if (milestonesItems.length === 0) {
            milestonesItems = <span className="reached-the-end">No new milestones.</span>
        }
        return (
            <div className="card content-card milestones-card" id="recent-milestones-list-card">
                <div className="card-body">
                    <h4 className="card-title">Recently Added Milestones</h4>
                    <MilestonesHeader />
                        {
                            isLoading ? <ContentLoader /> : milestonesItems
                        }
                </div>
            </div>
        );
    }
}


const mapStateToProps = state => ({
    isLoadingMilestones: state.milestones.isLoadingMilestones,
    isLoadingMoreMilestones: state.milestones.isLoadingMoreMilestones,
    didLoadMilestones: state.milestones.didLoadMilestones,
    isAddingMilestone: state.milestones.isAddingMilestone,
    milestonesList: state.milestones.milestonesList,
    total: state.milestones.total,
    page: state.milestones.page,
    searchString: state.milestones.searchString,
    lastPage: state.milestones.lastPage,
    perPage: state.milestones.perPage,
});

const mapDispatchToProps = dispatch => ({
    loadMilestones: () => dispatch(fetchMilestonesAction()),
    loadMoreMilestones: () => dispatch(fetchNextMilestonesAction()),
    load: () => dispatch(fetchMilestonesAction()),
    resetFilters: () => dispatch(clearMilestoneFiltersAction())
});

export default connect(mapStateToProps, mapDispatchToProps)(RecentMilestonesCard);
