import {
    ACT_MS_ADD_201,
    ACT_MS_ADD_INIT, ACT_MS_CLEAR_FILTERS,
    ACT_MS_FETCH_200,
    ACT_MS_FETCH_INIT,
    ACT_MS_FILTER_ASSIGNED_TO,
    ACT_MS_FILTER_CATEGORIES,
    ACT_MS_FILTER_VENTURES,
    ACT_MS_FILTER_TAG,
    ACT_MS_QUICK_FILTER,
    ACT_MS_SEARCH_UPDATE,
    ACT_MS_FETCH_NEXT_200,
    ACT_MS_FETCH_NEXT_INIT, ACT_MS_SORTING_UPDATE, ACT_MS_PROGRESSBAR_WIDTH
} from "../constants/actions";

const defaultState = {
    isLoadingMilestones: false,
    didLoadMilestones: false,
    isAddingMilestone: false,
    isLoadingMoreMilestones: false,
    milestonesList: [],
    total: 0,
    page: '',
    lastPage: '',
    searchString: '',
    perPage: 100,
    selectedVentures: [],
    selectedMilestoneCategories: [],
    selectedAssignedTos: [],
    selectedTag: null,
    dueDateFrom: '',
    dueDateTo: '',
    sortBy: 'due_date',
    sortDirection: 'asc',
    progressWidth: 100,
    progressWidthInit: false,
};


const milestonesReducer = (state = defaultState, action) => {
    console.log('MS reducer state action', state, action);
    let newMilestonesList;
    switch (action.type) {
        case ACT_MS_FETCH_NEXT_INIT:
            return {
                ...state,
                isLoadingMoreMilestones: true,
            };
        case ACT_MS_PROGRESSBAR_WIDTH:
            console.log('so changed width in reducer to ' + action.progressWidth);
            return {
                ...state,
                progressWidth: action.progressWidth,
                progressWidthInit: true,
            };
        case ACT_MS_FETCH_INIT:
            return {
                ...state,
                isLoadingMilestones: true,
                isLoadingMoreMilestones: false,
                didLoadMilestones: false,
                milestonesList: [],
            };
        case ACT_MS_FETCH_200:
        case ACT_MS_FETCH_NEXT_200:
            newMilestonesList = state.milestonesList;
            action.response.data.forEach(milestone => {
                if (newMilestonesList.find((loadedMilestone) => loadedMilestone.id === milestone.id) === undefined) {
                   newMilestonesList.push(milestone);
                }
            });
            return {
                ...state,
                isLoadingMilestones: false,
                isLoadingMoreMilestones: false,
                didLoadMilestones: true,
                milestonesList: newMilestonesList,
                total: action.response.total,
                perPage: action.response.per_page,
                page: action.response.current_page,
                lastPage: action.response.last_page,
            }
        case ACT_MS_SEARCH_UPDATE:
            return {
                ...state,
                searchString: action.searchString,
                didLoadMilestones: false,
                milestonesList: [],
            };
        case ACT_MS_SORTING_UPDATE:
            return {
                ...state,
                sortBy: action.sortBy,
                sortDirection: action.sortDirection,
                didLoadMilestones: false,
                milestonesList: [],
            };
        case ACT_MS_FILTER_ASSIGNED_TO:
            return {
                ...state,
                selectedAssignedTos: action.selectedAssignedTos,
                didLoadMilestones: false,
                milestonesList: [],
            };
        case ACT_MS_FILTER_CATEGORIES:
            return {
                ...state,
                selectedMilestoneCategories: action.selectedMilestoneCategories,
                didLoadMilestones: false,
                milestonesList: [],
            };
        case ACT_MS_FILTER_VENTURES:
            return {
                ...state,
                selectedVentures: action.selectedVentures,
                didLoadMilestones: false,
                milestonesList: [],
            };
        case ACT_MS_FILTER_TAG:
            return {
                ...state,
                selectedTag: action.selectedTag,
                didLoadMilestones: false,
                milestonesList: [],
            };
        case ACT_MS_CLEAR_FILTERS:
            return {
                ...state,
                selectedAssignedTos: [],
                selectedMilestoneCategories: [],
                selectedVentures: [],
                selectedTag: null,
                searchString: '',
                didLoadMilestones: false,
                milestonesList: [],
            };
        case ACT_MS_ADD_INIT:
            return {
                ...state,
                isAddingMilestone: true,
            };
        case ACT_MS_QUICK_FILTER:
            return {
                ...state,
                selectedAssignedTos: action.selectedAssignedTos,
                selectedMilestoneCategories: action.selectedMilestoneCategories,
                selectedVentures: action.selectedVentures,
                selectedTag: action.selectedTag,
                searchString: '',
                didLoadMilestones: false,
                milestonesList: [],
            };
        case ACT_MS_ADD_201:
            newMilestonesList = [action.milestone].concat(state.milestonesList);
            return {
                ...state,
                isAddingMilestone: false,
                milestonesList: newMilestonesList,
            };

    }
    return state;
};

export default milestonesReducer;
