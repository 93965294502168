import {takeEvery, takeLatest, put} from 'redux-saga/effects';
import {ACT_AUTH_TOKEN_SUCCESS, ACT_CAT_ADD_201, ACT_CAT_ADD_INIT, ACT_CAT_FETCH_INIT} from "../constants/actions";
import Api from "../services/ApiService";
import {addCategorySuccessAction, fetchCategoriesSuccessAction} from "../actions/categoriesActions";


function* addCategory(action) {
    const {status, data} = yield Api.post('milestone_categories', {name: action.categoryName});
    if (status === 201) {
        let category = data;
        category.new = true;
        yield put(addCategorySuccessAction(category));
    } else {
        console.log("Add cateogy is not a 201", status);
    }
}

function* fetchCategories(action) {
    const {status, data} = yield Api.get('milestone_categories');
    if(status === 200) {
        yield put(fetchCategoriesSuccessAction(data));
    } else {
        console.log("List categories is not a 200", status);
    }
}

export default function* categoriesSagas (){
    yield takeEvery(ACT_CAT_FETCH_INIT, fetchCategories)
    yield takeEvery(ACT_CAT_ADD_INIT, addCategory)
    yield takeLatest(ACT_AUTH_TOKEN_SUCCESS, fetchCategories);
}

