import React, {Component} from 'react';
import './_styles.scss';

const MilestonesHeader = () => {
    return <div className="row single-milestone-header">
        <div className="single-milestone-col col-4 col-md-6 col-xl-1">
            Venture
        </div>
        <div className="single-milestone-col col-8 col-md-6 col-xl-2 ">
            Category
        </div>
        <div className="single-milestone-col col-12 col-md-6 col-xl-3">
            Description
        </div>
        <div className="single-milestone-col col-12 col-md-6 col-xl-1 col-6">
            Due date
        </div>
        <div className="single-milestone-col col-12 col-md-10 col-xl-4 ">

        </div>
        <div className="single-milestone-col col-12 col-md-2 col-xl-1">

        </div>
    </div>;
}


export default MilestonesHeader;
