export const profileImageUrl = (user) => {
    if (user.profile_image && user.profile_image.url) {
        return user.profile_image.url;
    }
    return '/img/user_empty.png';
}


export const ventureImageUrl = (venture) => {
    if (venture.image && venture.image.url) {
        return venture.image.url;
    }
    return '/img/venture_empty.png';
}
