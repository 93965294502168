import {ACT_AUTH_LOGOUT_INIT, ACT_CAT_ADD_201, ACT_CAT_ADD_INIT, ACT_CAT_FETCH_200, ACT_CAT_FETCH_INIT} from "../constants/actions";

export const addCategoryAction  = (categoryName) => ({
    type: ACT_CAT_ADD_INIT,
    categoryName
});

export const addCategorySuccessAction = (category) => ({type: ACT_CAT_ADD_201, category});

export const fetchCategoriesAction = () => ({type: ACT_CAT_FETCH_INIT});
export const fetchCategoriesSuccessAction = (categories) => ({type: ACT_CAT_FETCH_200, categories});
