import {ACT_USR_ADD_201, ACT_USR_ADD_INIT, ACT_USR_FETCH_200, ACT_USR_FETCH_INIT} from "../constants/actions";

const defaultState = {
    isLoadingUsers: false,
    didLoadUsers: false,
    isAddingUser: false,
    usersList: [],
};


const usersReducer = (state = defaultState, action) => {
    console.log('usr reducer state action', state, action);
    switch (action.type) {
        case ACT_USR_FETCH_INIT:
            return {
                ...state,
                isLoadingUsers: true,
                didLoadUsers: false,
                usersList: [],
            };
        case ACT_USR_FETCH_200:
            return {
                ...state,
                isLoadingUsers: false,
                didLoadUsers: true,
                usersList: action.users,
            }
        case ACT_USR_ADD_INIT:
            return {
                ...state,
                isAddingUser: true,
            };
        case ACT_USR_ADD_201:
            let newUsersList = [action.user].concat(state.usersList);
            return {
                ...state,
                isAddingUser: false,
                usersList: newUsersList,
            };

    }
    return state;
};

export default usersReducer;
