import React, {Component} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {Link} from "react-router-dom";
import {toggleSidebarOffAction} from "../../../actions/commonActions";
import Sidebar from "../../organisms/Sidebar/Sidebar";

class SidebarItem extends Component {
    static propTypes = {
        name: PropTypes.string.isRequired,
        linkTo: PropTypes.string,
        iconClass: PropTypes.string.isRequired,
        isActive: PropTypes.bool,
        subItems: PropTypes.arrayOf(PropTypes.shape({
            name: PropTypes.string.isRequired,
            linkTo: PropTypes.string.isRequired,
        }))
    };

    static defaultProps = {
        isActive: false,
        linkTo: '',
        subItems: [],
    };

    constructor(props) {
        super(props);
        this.state = {
            toggled: false,
        };
    }

    rootNav(ev) {
        ev.preventDefault();
        const { subItems } = this.props;
        const hasSubItems = subItems.length !== 0;
        if (hasSubItems) {
            this.setState((prevState) => ({toggled: !prevState.toggled}))
        } else {
            alert('root nav');
        }
    }

    render() {
        const {isActive, name, iconClass, subItems, linkTo, toggleSidebarOff} = this.props;
        const {toggled} = this.state;
        const hasSubItems = subItems.length !== 0;
        let classNamesString = '';
        if (isActive && !hasSubItems) {
            classNamesString += 'navigation__active';
        }
        if (hasSubItems) {
            classNamesString = 'navigation__sub' + (toggled ? ' navigation__sub--toggled' : '');
        }
        let subItemsList = (<></>);
        let mainItem;
        if (hasSubItems) {
            mainItem = (<a href="#" onClick={ev => this.rootNav(ev)}>
                <i className={iconClass} /> {name}
            </a>);
            subItemsList = (
                <ul className="rollIn rollOut" style={{display: toggled ? 'block' : 'none' }}>
                    {subItems.map((obj, i) => (
                        <li key={i}>
                            <Link to={obj.linkTo} onClick={() => toggleSidebarOff()}>
                                {obj.name}
                            </Link>
                        </li>
                    ))}
                </ul>
            );
        } else {
            mainItem = (<Link to={linkTo} onClick={() => toggleSidebarOff()}>
                <i className={iconClass} /> {name}
            </Link>);
        }
        return (
            <>
                <li className={classNamesString}>
                    { mainItem }
                    { subItemsList }
                </li>
            </>
        )
    }
}


const mapDispatchToProps = dispatch => ({
    toggleSidebarOff: () => dispatch(toggleSidebarOffAction()),
});

export default connect(null, mapDispatchToProps)(SidebarItem);
