import {ACT_CAT_ADD_201, ACT_CAT_ADD_INIT, ACT_CAT_FETCH_200, ACT_CAT_FETCH_INIT} from "../constants/actions";

const defaultState = {
    isLoadingCategories: false,
    didLoadCategories: false,
    isAddingCategory: false,
    categoriesList: [],
};


const categoriesReducer = (state = defaultState, action) => {
    console.log('cat reducer state action', state, action);
    switch (action.type) {
        case ACT_CAT_FETCH_INIT:
            return {
                ...state,
                isLoadingCategories: true,
                didLoadCategories: false,
                categoriesList: [],
            };
        case ACT_CAT_FETCH_200:
            return {
                ...state,
                isLoadingCategories: false,
                didLoadCategories: true,
                categoriesList: action.categories,
            }
        case ACT_CAT_ADD_INIT:
            return {
                ...state,
                isAddingCategory: true,
            };
        case ACT_CAT_ADD_201:
            let newCategoriesList = [action.category].concat(state.categoriesList);
            return {
                ...state,
                isAddingCategory: false,
                categoriesList: newCategoriesList,
            };

    }
    return state;
};

export default categoriesReducer;
