import {takeEvery, put, takeLeading, takeLatest, select} from 'redux-saga/effects';
import {
    ACT_AUTH_TOKEN_SUCCESS, ACT_MS_ADD_201,
    ACT_MS_ADD_INIT,
    ACT_MS_FETCH_INIT, ACT_MS_FETCH_NEXT_INIT,
    ACT_MS_FILTER_ASSIGNED_TO,
    ACT_MS_FILTER_CATEGORIES,
    ACT_MS_FILTER_TAG,
    ACT_MS_FILTER_VENTURES,
    ACT_MS_QUICK_FILTER,
    ACT_MS_SEARCH_UPDATE,
} from "../constants/actions";
import Api from "../services/ApiService";
import {
    addMilestoneSuccessAction,
    fetchMilestonesAction,
    fetchMilestonesSuccessAction
} from "../actions/milestonesActions";
import {history} from "../history";

function* scrollToMilestones()
{
    const element = document.getElementById("milestones-list-card");
    const headerOffset = 90;
    const elementPosition = element.getBoundingClientRect().top;
    const currentScroll = window.scrollY;
    const offsetPosition = currentScroll + elementPosition - headerOffset;
    yield window.scrollTo({
        top: offsetPosition,
        behavior: "smooth"
    });
}

function* quickFilterHandler() {
    yield scrollToMilestones();
    yield put(fetchMilestonesAction());
}

function* addMilestone(action) {
    const {status, data} = yield Api.post('milestones', {
        due_date: action.dueDate,
        description: action.description,
        milestone_category_id: action.milestoneCategoryId,
        venture_id: action.ventureId,
        assigned_to_id: action.assignedTo,
        percent_done: 0,
    });
    if (status === 201) {
        let milestone = data;
        milestone.new = true;
        yield put(addMilestoneSuccessAction(milestone));
    } else {
        console.log("Add milestone is not a 201", status);
    }
}

const milestonesState = state => state.milestones;

const buildFetchUrl = (state, page = 1) => {
    let url = `milestones?page=${page}`;

    if(state.searchString.length > 1) {
        url += `&search=` + encodeURI(state.searchString)
    }

    if (state.selectedAssignedTos) {
        state.selectedAssignedTos.forEach((assignedTo) => {
            url += `&assigned_to_ids[]=` + assignedTo.value
        });
    }

    if (state.selectedVentures) {
        state.selectedVentures.forEach((venture) => {
            url += `&venture_ids[]=` + venture.value
        });
    }

    if (state.selectedMilestoneCategories) {
        state.selectedMilestoneCategories.forEach((milestoneCategory) => {
            url += `&milestone_category_ids[]=` + milestoneCategory.value
        });
    }

    if (state.selectedTag && state.selectedTag.value !== 'ALL') {
        url += `&filter_tag=` + state.selectedTag.value
    }

    if (state.sortBy) {
        url += `&sort_by=` + state.sortBy
    }

    if (state.sortDirection) {
        url += `&sort_direction=` + state.sortDirection
    }

    return url;
}

function* fetchNextMilestones(action) {
    const state = yield select(milestonesState);
    console.log('should load more');
    console.log(`current page ${state.page} last page  ${state.lastPage}`);
    if(state.page === state.lastPage) {
        return;
    }
    const nextPage = state.page + 1;
    const {status, data} = yield Api.get(buildFetchUrl(state, nextPage));
    if(status === 200) {
        yield put(fetchMilestonesSuccessAction(data));
    } else {
        console.log("List milestones is not a 200", status);
    }
}

function* fetchMilestones(action) {
    const state = yield select(milestonesState);
    const {status, data} = yield Api.get(buildFetchUrl(state));
    if(status === 200) {
        yield put(fetchMilestonesSuccessAction(data));
    } else {
        console.log("List milestones is not a 200", status);
    }
}

export default function* milestonesSagas (){
    yield takeLatest([
        ACT_AUTH_TOKEN_SUCCESS,
        ACT_MS_FETCH_INIT,
        ACT_MS_SEARCH_UPDATE,
        ACT_MS_FILTER_ASSIGNED_TO,
        ACT_MS_FILTER_CATEGORIES,
        ACT_MS_FILTER_VENTURES,
        ACT_MS_FILTER_TAG,
    ], fetchMilestones);
    yield takeLatest([
        ACT_MS_FETCH_NEXT_INIT
    ], fetchNextMilestones);
    yield takeEvery([ACT_MS_QUICK_FILTER, ACT_MS_SEARCH_UPDATE, ACT_MS_ADD_201], scrollToMilestones);
    yield takeLatest(ACT_MS_QUICK_FILTER, quickFilterHandler)
    yield takeEvery(ACT_MS_ADD_INIT, addMilestone);
}

