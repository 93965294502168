import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Api from "../../../services/ApiService";
import './_styles.scss'

export default class NewUserUpload extends Component {
    static propTypes = {
        onUploadDone: PropTypes.func.isRequired,
    };

    static defaultProps = {
        url: '/img/upload-icon.svg',
    };

    constructor(props) {
        super(props);
        this.state = {
            isUploading: false,
            url:  props.url,
        };
        this.fileUploadInput = React.createRef();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {url} = this.props;
        if (url !== prevProps.url)
        this.setState({
            url: this.props.url
        });
    }

    selectFile() {
        this.fileUploadInput.current.click();
    }

    setImageId(imageId) {
        this.props.onUploadDone(imageId);
    }

    uploadImage(ev) {
        this.setState({isUploading: true})
        Api.uploadOneFile('images', ev.target.files[0]).then((response) => {
            this.setState({isUploading: false});
            if (response.status === 201) {
                this.setImageId(response.data.id);
                this.setState({url: response.data.url});
            }
        });
    }

    render() {
        const {isUploading, url} = this.state;
        const uploading = isUploading ? 'uploading' : '';
        return <div className={'new-user-upload round-img-wrapper ' + uploading}>
            <input type="file" ref={this.fileUploadInput} style={{display: 'none'}} onChange={(ev) => this.uploadImage(ev)}/>
            {
                !isUploading ? <img className="round-img" src={url} alt="" onClick={() => this.selectFile()}/> : null
            }
        </div>
    }
}
