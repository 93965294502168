import React, {Component} from "react";
import './_styles.scss';
import LiveInput from "../LiveInput/LiveInput";
import Api from "../../../services/ApiService";
import {OverlayTrigger, Tooltip} from "react-bootstrap";

export default class CategoryItem extends Component {
    constructor(props) {
        super(props);
        this.state = {
            category: props.category,
            showAnimate: false,
            deleted: false,
            empty: false,
        };
    }

    componentDidMount() {
        setTimeout(() => this.setState({showAnimate: true}), 10);
    }

    delete() {
        const {category: { id }} = this.props;
        this.setState({deleted: true});
        Api.delete(`milestone_categories/${id}`).then((response) => {
           if(response.status !== 204 && response.status !== 404) {
               this.setState({deleted: false});
           } else {
               setTimeout(()=> this.setState({empty: true}), 1000);
           }
        }).catch(() => {
            this.setState({deleted: false});
        });
    }

    render() {
        const {category, showAnimate, deleted, empty} = this.state;
        let classes = [];
        if (category.new) {
            classes.push('new');
        }
        if (showAnimate) {
            classes.push('show');
        }
        if (deleted) {
            classes.push('deleted');
        }
        if(empty) {
            return <></>
        }
        return (
            <tr className={classes.join(' ')}>
                <td className="td-category-name"><LiveInput id={category.id} fieldName="name" endpoint="milestone_categories" value={category.name} /></td>
                <td className="text-right">{category.milestones_count}</td>
                <td className="text-right">
                    {
                        parseInt(category.milestones_count) === 0
                        ? <button type="button" className="row-action" onClick={() => this.delete()}>
                                <i className="zwicon-trash"/>
                        </button>
                        :  <OverlayTrigger
                                className="row-action"
                                placement="bottom"
                                overlay={
                                    <Tooltip className="text-limiter-overlay" id="tooltip-bottom">
                                        <p>Can't delete category because milestones are assigned to it</p>
                                    </Tooltip>
                                }
                            >
                            <span className="row-action disabled">
                                <i className="zwicon-trash"/>
                            </span>
                        </OverlayTrigger>
                    }
                </td>
            </tr>
        )
    }
}
