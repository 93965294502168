
// authActions.js
export const ACT_AUTH_NO_TOKEN_FOUND = 'ACT_AUTH_NO_TOKEN_FOUND';
export const ACT_AUTH_TOKEN_CHECK = 'ACT_AUTH_TOKEN_CHECK';
export const ACT_AUTH_TOKEN_SUCCESS = 'ACT_AUTH_TOKEN_SUCCESS';
export const ACT_AUTH_TOKEN_FAIL = 'ACT_AUTH_TOKEN_FAIL';
export const ACT_AUTH_TOKEN_EMPTY = 'ACT_AUTH_TOKEN_FAIL';
export const ACT_AUTH_LOGIN_FAILED = 'ACT_AUTH_LOGIN_FAILED';
export const ACT_AUTH_LOGIN_ATTEMPT = 'ACT_AUTH_LOGIN_ATTEMPT';
export const ACT_AUTH_LOGIN_SUCCESS = 'ACT_AUTH_LOGIN_SUCCESS';
export const ACT_AUTH_DEL_TOKEN = 'ACT_AUTH_DEL_TOKEN';
export const ACT_AUTH_LOGOUT_INIT = 'ACT_AUTH_LOGOUT_INIT';
export const ACT_AUTH_LOGOUT_SUCCESS = 'ACT_AUTH_LOGOUT_SUCCESS';

// categoriesActions.js
export const ACT_CAT_ADD_INIT = 'ACT_CAT_ADD_INIT';
export const ACT_CAT_ADD_201 = 'ACT_CAT_ADD_201';
export const ACT_CAT_UPDATE = 'ACT_CAT_UPDATE';
export const ACT_CAT_DELETE = 'ACT_CAT_DELETE';
export const ACT_CAT_FETCH_INIT = 'ACT_CAT_FETCH_INIT';
export const ACT_CAT_FETCH_200 = 'ACT_CAT_FETCH_200';

// venturesActions.js
export const ACT_VEN_ADD_INIT = 'ACT_VEN_ADD_INIT';
export const ACT_VEN_ADD_201 = 'ACT_VEN_ADD_201';
export const ACT_VEN_UPDATE = 'ACT_VEN_UPDATE';
export const ACT_VEN_DELETE = 'ACT_VEN_DELETE';
export const ACT_VEN_FETCH_INIT = 'ACT_VEN_FETCH_INIT';
export const ACT_VEN_FETCH_200 = 'ACT_VEN_FETCH_200';

// milestonesActions.js
export const ACT_MS_ADD_INIT = 'ACT_MS_ADD_INIT';
export const ACT_MS_ADD_201 = 'ACT_MS_ADD_201';
export const ACT_MS_UPDATE = 'ACT_MS_UPDATE';
export const ACT_MS_DELETE = 'ACT_MS_DELETE';
export const ACT_MS_FETCH_INIT = 'ACT_MS_FETCH_INIT';
export const ACT_MS_FETCH_NEXT_INIT = 'ACT_MS_FETCH_NEXT_INIT';
export const ACT_MS_RESET = 'ACT_MS_RESET';
export const ACT_MS_FETCH_200 = 'ACT_MS_FETCH_200';
export const ACT_MS_FETCH_NEXT_200 = 'ACT_MS_FETCH_NEXT_200';
export const ACT_MS_CLEAR_FILTERS = 'ACT_MS_CLEAR_FILTERS';
export const ACT_MS_SEARCH_UPDATE = 'ACT_MS_SEARCH_UPDATE';
export const ACT_MS_FILTER_VENTURES = 'ACT_MS_FILTER_VENTURES';
export const ACT_MS_FILTER_TAG = 'ACT_MS_FILTER_TAG';
export const ACT_MS_QUICK_FILTER = 'ACT_MS_QUICK_FILTER';
export const ACT_MS_FILTER_ASSIGNED_TO = 'ACT_MS_FILTER_ASSIGNED_TO';
export const ACT_MS_FILTER_CATEGORIES = 'ACT_MS_FILTER_CATEGORIES';
export const ACT_MS_SORTING_UPDATE = 'ACT_MS_SORTING_UPDATE';
export const ACT_MS_PROGRESSBAR_WIDTH = 'ACT_MS_PROGRESSBAR_WIDTH';

// usersActions.js
export const ACT_USR_ADD_INIT = 'ACT_USR_ADD_INIT';
export const ACT_USR_ADD_201 = 'ACT_USR_ADD_201';
export const ACT_USR_UPDATE = 'ACT_USR_UPDATE';
export const ACT_USR_DELETE = 'ACT_USR_DELETE';
export const ACT_USR_FETCH_INIT = 'ACT_USR_FETCH_INIT';
export const ACT_USR_FETCH_200 = 'ACT_USR_FETCH_200';

// commonActions.js
export const ACT_CMN_TOGGLE_SIDEBAR_ON = 'ACT_CMN_TOGGLE_SIDEBAR_ON';
export const ACT_CMN_TOGGLE_SIDEBAR_OFF = 'ACT_CMN_TOGGLE_SIDEBAR_OFF';
