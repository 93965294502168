import {put, all, call, takeEvery, fork, take} from 'redux-saga/effects';

import {
    ACT_AUTH_LOGIN_ATTEMPT,
    ACT_AUTH_LOGIN_SUCCESS, ACT_AUTH_LOGOUT_INIT,
    ACT_AUTH_TOKEN_CHECK,
    ACT_AUTH_TOKEN_EMPTY,
    ACT_AUTH_TOKEN_FAIL, ACT_AUTH_TOKEN_SUCCESS,
} from "../constants/actions";
import Api from "../services/ApiService";
import {
    loginFailedAction,
    loginSuccessAction, logoutSuccessAction,
    tokenCheckAction, tokenCheckFailAction,
    tokenCheckSuccessAction
} from "../actions/authActions";

import {history} from "../history";
const config = window.config;

function* logout() {
    localStorage.removeItem('access_token');
    localStorage.removeItem('expires_at');
    localStorage.removeItem('refresh_token');
    localStorage.removeItem('user');
    yield history.push('/login');
    yield put(logoutSuccessAction());
}

function* loginRedirector() {
    yield history.push('/login');
}

function* deleteToken() {
    yield localStorage.removeItem('access_token');
}

function* checkAuth() {
    const accessToken = localStorage.getItem('access_token');
    if (accessToken === undefined || accessToken === null) {
        yield put(tokenCheckFailAction());
    } else {
        const {status, data} = yield Api.get('users/me', false);
        if (status === 200) {
            yield put(tokenCheckSuccessAction(data));
        } else {
            yield put(tokenCheckFailAction());
        }
    }
}

function* attemptLogin(action) {
    const requestData = {
        grant_type: 'password',
        client_id: config.clientId,
        client_secret: config.clientSecret,
        username: action.username,
        password: action.password
    };
    const {status, data} = yield Api.post('oauth/tokens', requestData);
    if(status === 200) {
        yield put(loginSuccessAction(data.access_token, data.refresh_token, data.expires_in));
    } else {
        yield put(loginFailedAction());
    }
}

function* postLoginActions(action) {
    const d = new Date();
    const ts = d.getTime();
    localStorage.setItem('access_token', action.accessToken);
    localStorage.setItem('refresh_token', action.refreshToken);
    localStorage.setItem('expires_at', action.expiresIn * 1000 + ts);
    yield put(tokenCheckAction());
}

function* postTokenSuccess(action) {
    localStorage.setItem('user', JSON.stringify(action.user));
    if (history.location.pathname === '/login') {
        yield history.push('/overview');
    }
}

export default function* authSagas() {
    yield takeEvery(ACT_AUTH_TOKEN_CHECK, checkAuth);
    yield takeEvery(ACT_AUTH_LOGIN_ATTEMPT, attemptLogin);
    yield takeEvery(ACT_AUTH_LOGIN_SUCCESS, postLoginActions);
    yield takeEvery(ACT_AUTH_TOKEN_SUCCESS, postTokenSuccess);
    yield takeEvery([ACT_AUTH_TOKEN_FAIL, ACT_AUTH_TOKEN_EMPTY], loginRedirector);
    yield takeEvery(ACT_AUTH_TOKEN_FAIL, deleteToken);
    yield takeEvery(ACT_AUTH_LOGOUT_INIT, logout);
    yield put(tokenCheckAction());
}
