import React from "react";

export default function PageLoaderSpinner (props) {
    return (
        <div className="page-loader__spinner">
            <svg viewBox="25 25 50 50">
                <circle cx="50" cy="50" r="20" fill="none" strokeWidth="2" strokeMiterlimit="10"/>
            </svg>
        </div>
    )
};
