import {ACT_CMN_TOGGLE_SIDEBAR_OFF, ACT_CMN_TOGGLE_SIDEBAR_ON} from "../constants/actions";

const defaultState = {
    sidebarToggled: false
};

const commonReducer = (state = defaultState, action) => {
    switch (action.type) {
        case ACT_CMN_TOGGLE_SIDEBAR_ON:
            return {
                ...state,
                sidebarToggled: true,
            };
        case ACT_CMN_TOGGLE_SIDEBAR_OFF:
            return {
                ...state,
                sidebarToggled: false,
            }
    }
    return state;
};

export default commonReducer;
