import React, {Component} from 'react';
import {connect} from 'react-redux';
import './_styles.scss';
import {profileImageUrl} from "../../../helpers/urls";
import {quickMilestoneFilterAction} from "../../../actions/milestonesActions";
import UserOptionLabel from "../../atoms/UserOptionLabel/UserOptionLabel";

class UserStatsCard extends Component {

    render() {
        const {user, quickFilter} = this.props;
        const selectedAssignedTos = [{ value: user.id, label: UserOptionLabel(user)}];
        return <div
            className="user-stats-wrapper"
            onClick={() => quickFilter(selectedAssignedTos, [], [], null)}
        >
            <img src={profileImageUrl(user)} className="big-round-image"/>
            <span className="user-milestones-count">
                <span className="text">
                    {user.open_milestones_count < 10 ? '0' : ''}
                    {user.open_milestones_count}
                </span>
            </span>
        </div>
    }
}

const mapDispatchToProps = dispatch => ({
    quickFilter: (
        selectedAssignedTos,
        selectedMilestoneCategories,
        selectedVentures,
        selectedTag
    ) => dispatch(quickMilestoneFilterAction(selectedAssignedTos,
        selectedMilestoneCategories,
        selectedVentures,
        selectedTag
    )),
});

export default connect(null, mapDispatchToProps)(UserStatsCard);

