import React, {Component} from 'react';
import {connect} from 'react-redux';
import './_styles.scss';
import {ventureImageUrl} from "../../../helpers/urls";
import Api from "../../../services/ApiService";

class VentureMilestonePhoto extends Component {
    constructor(props) {
        super(props);
        let isNew;
        const {milestone} = this.props;
        if(new Date(milestone.created_at).getTime() > new Date().setDate(new Date().getDate() - 7)) {
            isNew = true;
        } else {
            isNew = false;
        }

        this.state = {
            isNew,
            isPending: false,
            justDeleted: false,
        };
    }
    render() {
        const { isPending, isNew } = this.state;
        const { milestone } = this.props;

        return <div className={'venture-milestones-photo'}>
            <img className="round-img" src={ventureImageUrl(milestone.venture)} alt=""/>
            {isNew ? <label className="badge-new">NEW</label> : null }
        </div>;
    }
}


const mapStateToProps = state => ({
    user: state.auth.user,
});

export default connect(mapStateToProps)(VentureMilestonePhoto);

