import React, {Component} from 'react';
import {connect} from 'react-redux';
import './_styles.scss';
import {
    fetchMilestonesAction,
    resetMilestonesAction, updateMilestoneAssignedToFilterAction, updateMilestoneCategoryFilterAction,
    updateMilestoneSearchAction, updateMilestoneTagFilterAction, updateMilestoneVentureFilterAction
} from "../../../actions/milestonesActions";
import Select from "react-select";
import {getMilestoneFilterOptions} from "../../../helpers/selectOptions";

class MilestonesCard extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedVenture: [],
            selectedMilestoneCategory: [],
            selectedAssignedTo: [],
            selectedTag: null,
        };
    }

    componentWillUnmount() {
        this.props.updateSearch('');
    }

    render() {
        const {
            didLoadCategories,
            didLoadVentures,
            didLoadUsers,
            venturesList,
            categoriesList,
            usersList,
            updateVentures,
            updateAssignedTos,
            updateCategories,
            updateTag,
            selectedVentures,
            selectedMilestoneCategories,
            selectedAssignedTos,
            selectedTag,
            user,
        } = this.props;
        const {venturesOptions, categoriesOptions, usersOptions } = getMilestoneFilterOptions(
            didLoadVentures,
            venturesList,
            didLoadCategories,
            categoriesList,
            didLoadUsers,
            usersList,
        );

        const statusOptions = [
            {value: 'ALL', label: 'All'},
            {value: 'FILTER_ONLY_NEW', label: 'New'},
            {value: 'FILTER_ONLY_INCOMPLETE', label: 'Open'},
            // {value: 'FILTER_ONLY_IN_PROGRESS', label: 'In progress'},
            {value: 'FILTER_ONLY_COMPLETED', label: 'Completed'},
        ];

        if (user.is_admin) {
            statusOptions.push({value: 'FILTER_ONLY_DELETED', label: 'Deleted'});
        }

        return (
            <div className="milestone-filters">
                <Select
                    className="react-select-container status-filter"
                    classNamePrefix="react-select"
                    placeholder="Status"
                    options={statusOptions}
                    value={selectedTag}
                    onChange={(selection) => updateTag(selection)}
                />
                <Select
                    className="react-select-container"
                    classNamePrefix="react-select"
                    placeholder="Venture"
                    options={venturesOptions}
                    isMulti
                    value={selectedVentures}
                    onChange={(selection) => updateVentures(selection)}
                />
                <Select
                    className="react-select-container"
                    classNamePrefix="react-select"
                    placeholder="Category"
                    options={categoriesOptions}
                    value={selectedMilestoneCategories}
                    onChange={(selection) => updateCategories(selection)}
                    isMulti
                />
                <Select
                    className="react-select-container"
                    classNamePrefix="react-select"
                    placeholder="Owner"
                    options={usersOptions}
                    value={selectedAssignedTos}
                    onChange={(selection) => updateAssignedTos(selection)}
                    isMulti
                />
            </div>
        );
    }
}


const mapStateToProps = state => ({
    didLoadCategories: state.categories.didLoadCategories,
    categoriesList: state.categories.categoriesList,
    didLoadVentures: state.ventures.didLoadVentures,
    venturesList: state.ventures.venturesList,
    didLoadUsers: state.users.didLoadUsers,
    usersList: state.users.usersList,
    selectedVentures: state.milestones.selectedVentures,
    selectedMilestoneCategories: state.milestones.selectedMilestoneCategories,
    selectedAssignedTos: state.milestones.selectedAssignedTos,
    selectedTag: state.milestones.selectedTag,
    user: state.auth.user,
});

const mapDispatchToProps = dispatch => ({
    loadMilestones: () => dispatch(fetchMilestonesAction()),
    updateSearch: (newValue) => dispatch(updateMilestoneSearchAction(newValue)),
    updateVentures: (selectedVentures) => dispatch(updateMilestoneVentureFilterAction(selectedVentures)),
    updateCategories: (selectedMilestoneCategories) => dispatch(updateMilestoneCategoryFilterAction(selectedMilestoneCategories)),
    updateAssignedTos: (selectedAssignedTos) => dispatch(updateMilestoneAssignedToFilterAction(selectedAssignedTos)),
    updateTag: (selectedTag) => dispatch(updateMilestoneTagFilterAction(selectedTag)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MilestonesCard);
