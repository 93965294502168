import React, {Component} from "react";
import {FormText, Modal} from "react-bootstrap";
import Flatpickr from "react-flatpickr";
import {connect} from 'react-redux';
import './_style.scss';
import Select from 'react-select'
import PropTypes from "prop-types";
import {addMilestoneAction} from "../../../actions/milestonesActions";
import UserModalListItem from "../../molecules/UserModalListItem/UserModalListItem";
import Api from "../../../services/ApiService";
import PageLoaderSpinner from "../../atoms/PageLoaderSpinner/PageLoderSpinner";

 class MilestoneReassignModal extends Component {
    static propTypes = {
        close: PropTypes.func.isRequired,
        show: PropTypes.bool.isRequired,
        didLoadUsers: PropTypes.bool.isRequired,
        usersList: PropTypes.array,
    };

    close() {
        this.props.close();
    }

    async updateAndClose(userId)
    {
        const {
            milestone,
        } = this.props;
        const {data} = await Api.patch(`milestones/${milestone.id}`, {assigned_to_id: userId})
        this.props.resetMilestone(data);
        this.close();
    }

    render() {
        const {
            show,
            didLoadUsers,
            usersList,
            milestone,
        } = this.props;
        const usersOptions = didLoadUsers
            ? usersList.map((user) => (
                    <UserModalListItem user={user} assignedToId={milestone.assigned_to_id} key={user.id} onChange={(userId) => this.updateAndClose(userId)}/>
                ))
            : <PageLoaderSpinner />;

        return (
            <Modal
                dialogClassName="milestone-reassign-modal"
                show={show}
                onHide={() => this.close()}
                centered
                enforceFocus={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Reassign milestone ownership</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {usersOptions}
                </Modal.Body>
            </Modal>
        )
    }
}

const mapPropsToState = state => ({
    didLoadUsers: state.users.didLoadUsers,
    usersList: state.users.usersList,
});

export default connect(mapPropsToState)(MilestoneReassignModal);
