import React, {Component} from 'react';
import "./_styles.scss";
import {profileImageUrl} from "../../../helpers/urls";

export default class UserModalListItem extends Component {
    userChange()
    {
        const {user, assignedToId} = this.props;

        if (user.id !== assignedToId) {
            this.props.onChange(user.id);
        }
    }

    render()
    {
        const {user, assignedToId} = this.props;
        return <div className={'user-modal-list-item ' + (user.id === assignedToId ? 'current-assigned-to' : '')} onClick={() => this.userChange(user.id)}>
            <img src={profileImageUrl(user)} className="big-round-image" />
            <span className="user-name"> {user.last_name}, {user.first_name}</span>
        </div>
    }
}
