import React, {Component} from "react";
import VenturesCard from "../../organisms/VenturesCard/VenturesCard";

export default class Ventures extends Component {
    render() {
        return (
          <div className="row">
              <div className="col-12">
                  <VenturesCard />
              </div>
          </div>
        );
    }
}
