import React, {Component} from "react";
import './_styles.scss';
import LiveInput from "../LiveInput/LiveInput";
import Api from "../../../services/ApiService";
import UploadableImage from "../../molecules/UploadableImage/UploadableImage";
import BootstrapSwitchButton from 'bootstrap-switch-button-react'

export default class UserItem extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user: props.user,
            showAnimate: false,
            deleted: false,
            empty: false,
        };
        this.user = JSON.parse(localStorage.getItem('user'));

    }

    componentDidMount() {
        setTimeout(() => this.setState({showAnimate: true}), 10);
    }

    delete()
    {
        return;
        const {user: { id }} = this.props;
        this.setState({deleted: true});
        Api.delete(`users/${id}`).then((response) => {
           if(response.status !== 204 && response.status !== 404) {
               this.setState({deleted: false});
           } else {
               setTimeout(()=> this.setState({empty: true}), 1000);
           }
        }).catch(() => {
            this.setState({deleted: false});
        });
    }

    updateIsAdmin(checked) {
        const {user} = this.state;
        Api.patch(`users/${user.id}`, {
            is_admin: checked ? 1 : 0
        });
    }

    updateIsActive(checked) {
        const {user} = this.state;
        Api.patch(`users/${user.id}`, {
            is_active: checked ? 1 : 0
        });
    }

    render() {
        const {user, showAnimate, deleted, empty} = this.state;
        let classes = ['users-table'];
        if (user.new) {
            classes.push('new');
        }
        if (showAnimate) {
            classes.push('show');
        }
        if (deleted) {
            classes.push('deleted');
        }
        if(empty) {
            return <></>
        }
        const url = user.profile_image?.url ?? '/img/upload-icon.svg';
        return (
            <tr className="tr-user">
                <td className="td-first-img" width="10%"><UploadableImage url={url} fieldName="profile_image_id" endpoint="users" id={user.id}/></td>
                <td className="td-user-first-name" width="20%">
                    <LiveInput separateControls={true} id={user.id} fieldName="first_name" endpoint="users" value={user.first_name} />
                </td>
                <td className="td-user-last-name" width="20%">
                    <LiveInput separateControls={true} id={user.id} fieldName="last_name" endpoint="users" value={user.last_name} />
                </td>
                <td className="td-user-email" width="30%">
                    <LiveInput separateControls={true} id={user.id} fieldName="email" endpoint="users" value={user.email} />
                </td>
                <td className="text-right">
                    {user.milestones_count}
                </td>
                <td>
                    <BootstrapSwitchButton
                        checked={user.is_admin === 1}
                        width={100}
                        size="s"
                        onlabel='Admin'
                        offlabel='User'
                        onstyle="outline-light"
                        offstyle="outline-light"
                        block={true}
                        disabled={user.id === this.user.id}
                        onChange={(checked) => {
                            this.updateIsAdmin(checked);
                        }}
                    />
                </td>
                <td>
                    <BootstrapSwitchButton
                        checked={user.is_active === 1}
                        width={100}
                        size="s"
                        onlabel='Active'
                        offlabel='Inactive'
                        onstyle="outline-light"
                        offstyle="outline-light"
                        block={true}
                        disabled={user.id === this.user.id}
                        onChange={(checked) => {
                            this.updateIsActive(checked);
                        }}
                    />
                </td>
            </tr>
        )
    }
}
