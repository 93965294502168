import {takeLatest, put, takeLeading} from 'redux-saga/effects';
import {ACT_AUTH_TOKEN_SUCCESS, ACT_USR_ADD_201, ACT_USR_ADD_INIT, ACT_USR_FETCH_INIT} from "../constants/actions";
import Api from "../services/ApiService";
import {addCategorySuccessAction, fetchCategoriesSuccessAction} from "../actions/categoriesActions";
import {fetchUsersSuccessAction} from "../actions/usersActions";


function* addUser(action) {
    const {status, data} = yield Api.post('users', {name: action.categoryName});
    if (status === 201) {
        let category = data;
        category.new = true;
        // yield put(addCategorySuccessAction(category));
    } else {
        console.log("Add user is not a 201", status);
    }
}

function* fetchUsers(action) {
    const {status, data} = yield Api.get('users');
    if(status === 200) {
        yield put(fetchUsersSuccessAction(data));
    } else {
        console.log("List users is not a 200", status);
    }
}

export default function* categoriesSagas (){
    yield takeLeading(ACT_USR_FETCH_INIT, fetchUsers)
    yield takeLeading(ACT_USR_ADD_INIT, addUser)
    yield takeLatest(ACT_AUTH_TOKEN_SUCCESS, fetchUsers);
}

