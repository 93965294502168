import React, {Component} from 'react';
import {connect} from 'react-redux';
import SidebarItem from "../../molecules/SidebarItem/SidebarItem";
import './_styles.scss';
import {toggleSidebarOffAction} from "../../../actions/commonActions";

class Sidebar extends Component {
    render() {
        const {user, sidebarToggled, toggleSidebarOff} = this.props;
        return (
            <>
                {
                    sidebarToggled ? <div className="escape-sidebar-area" onClick={()=> toggleSidebarOff()}> </div> : null
                }
                <aside className={'sidebar ' + (sidebarToggled ? 'toggled' : null)}>
                    <div className="scrollbar">
                        <br />
                        <ul className="navigation">
                            <SidebarItem
                                key={1}
                                isActive={false}
                                linkTo="/overview"
                                name="Overview"
                                iconClass="zwicon-layout-4"
                            />
                            <SidebarItem
                                key={2}
                                iconClass="zwicon-backlog"
                                name="Milestones"
                                linkTo="/milestones"
                            />
                            {
                                user.is_admin === 1
                                    ? <SidebarItem
                                        key={3}
                                        isActive={true}
                                        name="Settings"
                                        iconClass="zwicon-cog"
                                        subItems={[
                                            {'name': 'Users', 'linkTo': '/settings/users'},
                                            {'name': 'Ventures', 'linkTo': '/settings/ventures'},
                                            {'name': 'Categories', 'linkTo': '/settings/categories'},
                                        ]}
                                    />
                                    : null
                            }
                        </ul>
                    </div>
                </aside>
            </>
        );
    }
}

const mapStateToProps = state => ({
   user: state.auth.user,
   sidebarToggled: state.common.sidebarToggled,
});

const mapDispatchToProps = dispatch => ({
    toggleSidebarOff: () => dispatch(toggleSidebarOffAction()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
