import React, {Component} from 'react';
import PropTypes from 'prop-types';
import AddButton from '../../atoms/AddButton/AddButtons';
import './_styles.scss'

export default class QuickAddBox extends Component{
    static propTypes = {
        action: PropTypes.func.isRequired,
        placeholder: PropTypes.string.isRequired,
    };

    constructor(props) {
        super(props);
        this.state = {
            showInputBox: false,
            text: '',
        };
        this.quickInput = React.createRef();
    }

    componentDidMount() {
        this.setState({
            showInputBox: false,
            text: '',
        });
    }

    cancel() {
        this.setState({showInputBox: false, text: ''});
    }

    save() {
        const {text} = this.state;
        const {action} = this.props
        action(text);
        this.setState({showInputBox: false});
        setTimeout(() => this.setState({ text: ''}), 200);
    }

    show() {
        this.setState({showInputBox: true})
        setTimeout( () => this.quickInput.current.focus(), 100);
    }

    keyDown(ev) {
        if (ev.keyCode === 13) {
         this.save();
        }
        if (ev.keyCode === 27) {
            this.cancel()
        }
    }

    render() {
        const {showInputBox, text} = this.state;
        const {placeholder, action} = this.props
        return <>
            <div className={ 'overlay-input ' + (showInputBox ? '' : 'hide') }>
                <input
                    className="form-control"
                    placeholder={placeholder}
                    value={text}
                    onChange={(ev) => this.setState({text: ev.target.value})}
                    ref={this.quickInput}
                    onKeyDown={(ev) => this.keyDown(ev)}
                />
                <button type="button" onClick={() => this.save()}>
                    <i className="zwicon-checkmark" />
                </button>
                <button type="button" onClick={() => this.cancel()}>
                    <i className="zwicon-close" />
                </button>
            </div>
            <div className={ 'add-button ' + (showInputBox ? 'hide' : '') }  onClick={() => this.show()}>
                <AddButton/>
            </div>
        </>;

    }
}
