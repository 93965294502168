import React, {Component} from 'react';
import StatsCard from "../../molecules/StatsCard/StatsCard";
import OverviewCards from "../../organisms/OverviewCards/OverviewCards";
import "./_styles.scss";
import Milestones from "../Milestones/Milestones";
import RecentMilestonesCard from "../../organisms/RecentMilestonesCard/RecentMilestonesCard";
import MilestonesCard from "../../organisms/MilestonesCard/MilestonesCard";
import {VersionDisplay} from "../../organisms/VersionDisplay/VersionDisplay";

export default class Overview extends Component {
    render() {
        return <>
            <div className="overview-page">
                <OverviewCards />
            </div>
            <div className="row">
                <div className="col-12">
                    <RecentMilestonesCard />
                </div>
            </div>
            <Milestones />
            <VersionDisplay />
        </>;
    }
}
