import React, {Component} from 'react';
import "./_styles.scss";
import {profileImageUrl} from "../../../helpers/urls";
import {ligaDateFormat, shortDateFormat} from "../../../helpers/dateFormat";

export default class UserComment extends Component {
    render()
    {
        const {comment} = this.props;
        const image = comment.is_system_message
            ? <img src='/img/zg.png' className="round-img no-width" />
            : <img src={profileImageUrl(comment.user)} className="round-img" />;
        let text = comment.message;
        if (comment.is_system_message) {
            switch(comment.comment_type.type) {
                case 'SYS_CREATE':
                    text = 'Created milestone.';
                    break;
                case 'SYS_PROGRESS_UPDATE':
                    const newProgress = comment.system_message_payload.percent_done;
                    text = `Updated progress to ${newProgress}%.`;
                    break;
                case 'SYS_ASSIGN':
                    const newUser =
                        comment.second_user.last_name
                        + ', '
                        + comment.second_user.first_name;
                    text = `Transferred milestone to ${newUser}.`;
                    break;
                case 'SYS_DELETED':
                    text = 'Deleted milestone.';
                    break;
                case 'SYS_RESTORED':
                    text = 'Restored milestone.';
                    break;
                case 'SYS_MARK_AS_COMPLETED':
                    text = 'Marked milestone as completed.';
                    break;
                case 'SYS_NOT_COMPLETED':
                    text = 'Marked milestone as not completed.';
                    break;
            }
        }
        return <div className='user-comment'>
            {image}
            <span className="user-name">{comment.user.last_name}, {comment.user.first_name}</span>
            <span className="comment-date">{shortDateFormat(comment.created_at)}</span>
            <span className="user-message">{text}</span>
        </div>
    }
}
