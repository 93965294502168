import React, {Component} from 'react';
import {connect} from 'react-redux';
import './_styles.scss';
import {OverlayTrigger, Tooltip} from "react-bootstrap";

class MilestoneReassignMobileButton extends Component {
    render() {
        const {user, milestone} = this.props;
        const blockerText = milestone.percent_done === 100 ? 'Completed milestones are locked' : 'Only milestone owner can transfer ownership';
        return user.is_admin || (user.id === milestone.assigned_to_id && milestone.percent_done !== 100)
            ? <div className="milestone-mobile-action-buttons" onClick={() => this.props.openReassignBar()}>
                <img src="/img/users.png" width="35"/>
            </div>
            : <OverlayTrigger
                placement="left"
                delay={{show: 250, hide: 400}}
                overlay={
                    <Tooltip id="validation-blocker-tooltip">
                        <div>
                            {blockerText}
                        </div>
                    </Tooltip>
                }
            >
                <div className="milestone-mobile-action-buttons">
                    <img src="/img/users.png" width="35"/>
                </div>
            </OverlayTrigger>;
    }
}


const mapStateToProps = state => ({
    user: state.auth.user,
});

export default connect(mapStateToProps)(MilestoneReassignMobileButton);

