import React, {Component} from "react";
import MilestonesCard from "../../organisms/MilestonesCard/MilestonesCard";
import MilestoneFilters from "../../organisms/MilestoneFilters/MilestoneFilters";

export default class Milestones extends Component {
    render() {
        return (
          <div className="row">
              <div className="col-12">
                  <MilestonesCard />
              </div>
          </div>
        );
    }
}
