import React, {Component} from 'react';
import {connect} from 'react-redux';
import QuickAddBox from "../../molecules/QuickAddBox/QuickAddBox";
import {addVentureAction, fetchVenturesAction} from "../../../actions/venturesActions";
import ContentLoader from "../../molecules/ContentLoader/ContentLoader";
import VentureRow from "../VentureRow/VentureRow";

class VenturesCard extends Component {

    constructor(props) {
        super(props);
        this.tableRef = React.createRef();
        this.state = {
            fixPlusSign: false,
        };
    }

    componentDidMount() {
        this.props.fetchVentures();
        window.addEventListener('scroll', (ev) => this.handleScroll(ev));
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', (ev) => this.handleScroll(ev));
    }

    handleScroll(ev) {
        if (this.tableRef.current === null) {
            return;
        }
        const {top, bottom} = this.tableRef.current.getBoundingClientRect();
        if (top < 65 && bottom > 150) {
            this.setState({fixPlusSign: true});
        } else {
            this.setState({fixPlusSign: false});
        }
    }

    render() {
        const {fixPlusSign} = this.state;
        const {addVenture, isLoadingVentures, venturesList} = this.props;
        if (isLoadingVentures) {
            return <ContentLoader />
        }
        const venturesRows = venturesList.map((venture) => (<VentureRow key={venture.id} venture={venture} />));
        return (
            <div className="card content-card" ref={this.tableRef}>
                <div className="card-body">
                    <h4 className="card-title">Ventures</h4>
                    <div className={fixPlusSign ? 'table-plus-icon fixed-plus': 'table-plus-icon'}>
                        <QuickAddBox placeholder="Venture name" action={addVenture}/>
                    </div>
                    <table className="table table-striped mb-0">
                        <thead>
                        <tr>
                            <th width="1"></th>
                            <th>Venture Name</th>
                            <th className="text-right">Total milestones</th>
                            <th></th>
                        </tr>
                        </thead>
                        <tbody>
                            { venturesRows.length ? venturesRows : <tr><td colSpan="9">--- Ventures list is empty ---</td></tr> }
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    venturesList: state.ventures.venturesList,
    isLoadingVentures: state.ventures.isLoadingVentures,
})

const mapDispatchToProps = dispatch => ({
    fetchVentures: () => dispatch(fetchVenturesAction()),
    addVenture: (ventureName) => dispatch(addVentureAction(ventureName)),
})

export default connect(mapStateToProps, mapDispatchToProps)(VenturesCard);
