import React, {Component} from "react";
import {Modal, OverlayTrigger, Tooltip} from "react-bootstrap";
import {connect} from 'react-redux';
import './_style.scss';
import PropTypes from "prop-types";
import NewUserUpload from "../../molecules/NewUserUpload/NewUserUpload";
import Api from "../../../services/ApiService";
import PageLoaderSpinner from "../../atoms/PageLoaderSpinner/PageLoderSpinner";
import {tokenCheckAction} from "../../../actions/authActions";

class EditProfileModal extends Component {
    static propTypes = {
        close: PropTypes.func.isRequired,
        show: PropTypes.bool.isRequired,
    };

    constructor(props) {
        super(props);
        const {user} = props;
        this.state = {
            lastName: user.last_name,
            firstName: user.first_name,
            email: user.email,
            currentPassword: '',
            password1: '',
            password2: '',
            profileImageId: user.profile_image_id,
            profileImageUrl: user.profile_image ? user.profile_image.url : '/img/upload-icon.svg',
            blocked: true,
            blocker: 'Please fill all fields',
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { show } = this.props;
        if ((prevProps.show && !show) || (!prevProps.show && show)) {
            const {user} = this.props;
            console.log('yeah yeah will update');
            console.log(user.profile_image_id ? user.profile_image.url : '/img/upload-icon.svg');
            this.setState({
                lastName: user.last_name,
                firstName: user.first_name,
                email: user.email,
                password1: '',
                password2: '',
                profileImageId: user.profile_image_id,
                profileImageUrl: user.profile_image_id ? user.profile_image.url : '/img/upload-icon.svg',
                blocked: true,
                blocker: 'Please fill all fields',
            });
        }
    }

    updateBlockers()
    {
        const {
            lastName,
            firstName,
            email,
            currentPassword,
            password1,
            password2,
        } = this.state;
        if (password1.length > 0 && currentPassword.length === 0 ) {
            this.setState({blocked: true, blocker: 'Please enter current password.'});
        } else if (password1 !== password2) {
            this.setState({blocked: true, blocker: 'Password confirmation not matching password'});
        } else if (password1.length > 0 && password1.length < 6) {
            this.setState({blocked: true, blocker: 'Password too short'});
        } else if (email.length < 4) {
            this.setState({blocked: true, blocker: 'Please enter a valid email'});
        } else if ((lastName + lastName).length < 2 ) {
            this.setState({blocked: true, blocker: 'Please fill user first name & last name'});
        } else {
            this.setState({blocked: false, blocker: ''});
        }
    }

    close() {
        this.setState({
            lastName: '',
            firstName: '',
            email: '',
            currentPassword: '',
            password1: '',
            password2: '',
            profileImageId: null,
            isPending: false,
            error: '',
            blocked: true,
        });
        this.props.close();
    }

    async save(ev) {
        const {
            lastName,
            firstName,
            email,
            currentPassword,
            password1,
            blocked,
            profileImageId,
        } = this.state;
        const {
            userAdded,
            user,
            tokenCheck,
        } = this.props;
        ev.preventDefault();
        if (blocked) {
            return;
        }
        try {
            this.setState({
                isPending: true,
                error: '',
            });
            const {data, status} = await Api.patch('users/' + user.id, {
                last_name: lastName,
                first_name: firstName,
                email,
                current_password: currentPassword,
                password: password1,
                profile_image_id: profileImageId
            }, false);
            if (status === 200) {
                this.setState({
                    isPending: false,
                });
                tokenCheck();
                this.close();
            } else {
                this.setState({
                    isPending: false,
                    error: data.message,
                });
            }

        } catch (ev) {
            this.setState({
                isPending: false,
                error: 'Error updating profile'
            });
        }
    }

    imageUpdate(imageId) {
        this.setState({profileImageId : imageId});
    }

    inputUpdate(fieldName, value) {
        const newState = {error: ''};
        newState[fieldName] = value;
        this.setState(
            newState,
            () => this.updateBlockers()
        );
    }

    render() {
        const {
            lastName,
            firstName,
            email,
            currentPassword,
            password1,
            password2,
            blocked,
            blocker,
            error,
            isPending,
            profileImageUrl,
        } = this.state;
        const { show } = this.props;

        let submitButton = blocked
            ? <OverlayTrigger
                    placement="bottom"
                    delay={{ show: 250, hide: 400 }}
                    overlay={
                        <Tooltip id="validation-blocker-tooltip">
                            <div>
                                {blocker}
                            </div>
                        </Tooltip>
                    }
                >
                    <div>
                        <button onClick={(ev) => ev.preventDefault()}><i className="zwicon-checkmark" /></button>
                    </div>
                </OverlayTrigger>
            : <button type="submit"><i className="zwicon-checkmark" /></button>;
        if (isPending) {
            submitButton = <PageLoaderSpinner />
        }
        return (
            <Modal
                dialogClassName="add-user-modal"
                show={show}
                onHide={() => this.close()}
                centered
                enforceFocus={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Edit profile</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form autoComplete="off" onSubmit={(ev) => this.save(ev)}>
                      <table border="0" width="100%">
                        <tr>
                            <td width="1">
                                <NewUserUpload url={profileImageUrl} onUploadDone={imageId => this.imageUpdate(imageId)}/>
                            </td>
                            <td>
                                <div className="form-group">
                                    <input
                                        name="new_last_name"
                                        type="text"
                                        className="form-control"
                                        placeholder="Last name"
                                        value={lastName}
                                        autoComplete="new_last_name"
                                        onChange={(ev) => this.inputUpdate('lastName', ev.target.value)}
                                    />
                                </div>

                                <div className="form-group">
                                    <input
                                        name="new_first_name"
                                        type="text"
                                        className="form-control"
                                        placeholder="First name"
                                        value={firstName}
                                        autoComplete="new_first_name"
                                        onChange={(ev) => this.inputUpdate('firstName', ev.target.value)}
                                    />
                                </div>

                                <div className="form-group">
                                    <input
                                        name="new_email"
                                        type="email"
                                        className="form-control"
                                        placeholder="Email Address"
                                        value={email}
                                        autoComplete="new_email"
                                        onChange={(ev) => this.inputUpdate('email', ev.target.value)}
                                    />
                                </div>

                                <div className="form-group">
                                    <input
                                        type="password"
                                        className="form-control"
                                        placeholder="Current password"
                                        min="6"
                                        value={currentPassword}
                                        autoComplete="off"
                                        onChange={(ev) => this.inputUpdate('currentPassword', ev.target.value)}
                                    />
                                </div>

                                <div className="form-group">
                                    <input
                                        type="password"
                                        className="form-control"
                                        placeholder="Password"
                                        min="6"
                                        value={password1}
                                        autoComplete="off"
                                        onChange={(ev) => this.inputUpdate('password1', ev.target.value)}
                                    />
                                </div>

                                <div className="form-group">
                                    <input
                                        type="password"
                                        className="form-control"
                                        placeholder="Confirm password"
                                        value={password2}
                                        autoComplete="off"
                                        onChange={(ev) => this.inputUpdate('password2', ev.target.value)}
                                    />
                                </div>
                            </td>
                        </tr>
                    </table>
                    <br />
                    <span className="error-message">{error}</span>
                    <br />
                    { submitButton }
                    </form>
                </Modal.Body>
            </Modal>
        )
    }
}

const mapPropsToState = state => ({
    user: state.auth.user,

});

const mapDispatchToProps = dispatch => ({
    tokenCheck: () => dispatch(tokenCheckAction()),
});

export default connect(mapPropsToState, mapDispatchToProps)(EditProfileModal);
