import React, {Component} from "react";
import './_styles.scss';
import LiveInput from "../LiveInput/LiveInput";
import Api from "../../../services/ApiService";
import UploadableImage from "../../molecules/UploadableImage/UploadableImage";

export default class VentureRow extends Component {
    constructor(props) {
        super(props);
        this.state = {
            venture: props.venture,
            showAnimate: false,
            deleted: false,
            empty: false,
        };
    }

    componentDidMount() {
        setTimeout(() => this.setState({showAnimate: true}), 10);
    }

    delete()
    {
        const {venture: { id }} = this.props;
        this.setState({deleted: true});
        Api.delete(`ventures/${id}`).then((response) => {
           if(response.status !== 204 && response.status !== 404) {
               this.setState({deleted: false});
           } else {
               setTimeout(()=> this.setState({empty: true}), 1000);
           }
        }).catch(() => {
            this.setState({deleted: false});
        });
    }

    render() {
        const {venture, showAnimate, deleted, empty} = this.state;
        let classes = [];
        if (venture.new) {
            classes.push('new');
        }
        if (showAnimate) {
            classes.push('show');
        }
        if (deleted) {
            classes.push('deleted');
        }
        if(empty) {
            return <></>
        }
        const url = venture.image?.url ?? '/img/upload-icon.svg';
        return (
            <tr className={classes.join(' ')}>
                <td className="td-first-img"><UploadableImage url={url} fieldName="image_id" endpoint="ventures" id={venture.id}/></td>
                <td className="td-venture-name"><LiveInput id={venture.id} fieldName="name" endpoint="ventures" value={venture.name} /></td>
                <td className="text-right">{venture.milestones_count}</td>
                <td className="text-right">
                    <button type="button" className="row-action" onClick={() => this.delete()}>
                        <i className="zwicon-trash"/>
                    </button>
                </td>
            </tr>
        )
    }
}
