import React, {Component} from "react";
import CategoriesCard from "../../organisms/CategoriesCard/CategoriesCard";

export default class Categories extends Component {
    render() {
        return (
          <div className="row">
              <div className="col-12">
                  <CategoriesCard />
              </div>
          </div>
        );
    }
}
