import React, {Component} from 'react';
import StatsCard from "../../molecules/StatsCard/StatsCard";
import Api from "../../../services/ApiService";
import ContentLoader from "../../molecules/ContentLoader/ContentLoader";
import VentureStatsCard from "../../molecules/VentureStatsCard/VentureStatsCard";
import UserStatsCard from "../../molecules/UserStatsCard/UserStatsCard";
import TitleCard from "../../atoms/TitleCard/TitleCard";

export default class OverviewCards extends Component {

    constructor(props) {
        super(props);
        this.state = {
            milestoneCategories: [],
            ventures: [],
            users: [],
            milestoneCategoriesLoaded: false,
            venturesLoaded: false,
            usersLoaded: false,
        };

    }

    componentDidMount() {
        this.getCategories();
        this.getVentures();
        this.getUsers();
    }

    async getCategories()
    {
        try {
            const {status, data} = await Api.get('stats/milestone_categories', false);
            if (status === 200) {
                this.setState({
                    milestoneCategories: data,
                    milestoneCategoriesLoaded: true,
                });
            } else {
                setTimeout(() => this.getCategories(), 5000);
            }
        } catch (e) {
            setTimeout(() => this.getCategories(), 5000);
        }
    }

    async getVentures()
    {
        try {
            const {status, data} = await Api.get('stats/ventures', false);
            if (status === 200) {
                this.setState({
                    ventures: data,
                    venturesLoaded: true,
                });
            } else {
                setTimeout(() => this.getVentures(), 5000);
            }
        } catch (e) {
            setTimeout(() => this.getVentures(), 5000);
        }
    }

    async getUsers()
    {
        try {
            const {status, data} = await Api.get('users', false);
            if (status === 200) {
                this.setState({
                    users: data,
                    usersLoaded: true,
                });
            } else {
                setTimeout(() => this.getUsers(), 5000);
            }
        } catch (e) {
            setTimeout(() => this.getVentures(), 5000);
        }
    }

    render() {
        const {
            milestoneCategoriesLoaded,
            usersLoaded,
            venturesLoaded,
            users,
            milestoneCategories,
            ventures
        } = this.state;
        return (<>
            <TitleCard title="Categories" />
            <div className="row">
                <br />
                { !milestoneCategoriesLoaded ?  <ContentLoader /> : null }
                { milestoneCategories.map(milestoneCategory => <StatsCard milestoneCategory={milestoneCategory} key={milestoneCategory.id} />) }
            </div>
            <TitleCard title="Ventures" />
            <div className="row">
                { !venturesLoaded ?  <ContentLoader /> : null }
                { ventures.map(venture => <VentureStatsCard venture={venture} key={venture.id} />) }
            </div>
            <TitleCard title="Users" />
            <div className="row">
                { !usersLoaded ?  <ContentLoader /> : null }
                { users.map(user => <UserStatsCard user={user}  key={user.id}/>) }
            </div>
        </>
        );
    }
}
