import React, {Component} from 'react';
import {connect} from 'react-redux';
import './_styles.scss';
import ContentLoader from "../../molecules/ContentLoader/ContentLoader";
import {
    clearMilestoneFiltersAction,
    fetchMilestonesAction,
    fetchNextMilestonesAction,
} from "../../../actions/milestonesActions";
import MilestoneItem from "../MilestoneItem/MilestoneItem";
import MilestoneFilters from "../MilestoneFilters/MilestoneFilters";
import MilestonesHeader from "../../atoms/MilestonesHeader/MilestonesHeader";

class MilestonesCard extends Component {

    constructor(props) {
        super(props);
        this.state = {
            fixPlusSign: false,
            showAddModal: false,
        };
        this.tableRef = React.createRef();
    }

    componentDidMount() {
        this.props.loadMilestones();
        window.addEventListener('scroll', (ev) => this.handleScroll(ev));
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', (ev) => this.handleScroll(ev));
        this.props.resetFilters();
    }

    nextPage()
    {
       const { loadMoreMilestones } = this.props;
       loadMoreMilestones();
    }

    handleScroll(ev) {
        const {
            isLoadingMilestones,
        } = this.props;
        if(this.tableRef.current === null) {
            return;
        }
        const {top, bottom} = this.tableRef.current.getBoundingClientRect();
        if (!isLoadingMilestones && bottom < window.innerHeight) {
            this.nextPage();
        }
        if (top < 65 && bottom > 150) {
            this.setState({fixPlusSign: true});
        } else {
            this.setState({fixPlusSign: false});
        }
    }

    render() {
        const {
            isLoadingMoreMilestones,
            isLoadingMilestones,
            didLoadMilestones,
            milestonesList,
            total,
            lastPage,
            page,
        } = this.props;

        let milestonesItems = milestonesList.map((milestone) => {
            return <MilestoneItem milestone={milestone} key={milestone.id} />
        });
        if (milestonesItems.length === 0) {
            milestonesItems = <span className="reached-the-end">No milestones found matching selected filters.</span>
        }
        let subtitle = total > 0
            ? `${total} Milestone` +  (total > 1 ? 's' : '') + ' in total'
            : <span>&nbsp;</span>;
        const endOfListBool =  page > 0 && lastPage === page;
        return (
            <div className="card content-card milestones-card" id="milestones-list-card" ref={this.tableRef}>
                <div className="card-body">
                    <h4 className="card-title">Milestones</h4>
                    <h6 className="card-subtitle">

                        {
                            !didLoadMilestones || isLoadingMilestones ? 'Searching...' : subtitle
                        }
                    </h6>

                    <MilestoneFilters />

                    <MilestonesHeader />
                    {
                            !didLoadMilestones || isLoadingMilestones
                                ? <ContentLoader />
                            : milestonesItems
                        }
                        {
                            !endOfListBool && isLoadingMoreMilestones ? <ContentLoader /> : null
                        }
                        {
                            endOfListBool && milestonesItems.length > 0 ? <span className="reached-the-end">You've reached the end of the list.</span> : null
                        }
                </div>
            </div>
        );
    }
}


const mapStateToProps = state => ({
    isLoadingMilestones: state.milestones.isLoadingMilestones,
    isLoadingMoreMilestones: state.milestones.isLoadingMoreMilestones,
    didLoadMilestones: state.milestones.didLoadMilestones,
    isAddingMilestone: state.milestones.isAddingMilestone,
    milestonesList: state.milestones.milestonesList,
    total: state.milestones.total,
    page: state.milestones.page,
    searchString: state.milestones.searchString,
    lastPage: state.milestones.lastPage,
    perPage: state.milestones.perPage,
});

const mapDispatchToProps = dispatch => ({
    loadMilestones: () => dispatch(fetchMilestonesAction()),
    loadMoreMilestones: () => dispatch(fetchNextMilestonesAction()),
    load: () => dispatch(fetchMilestonesAction()),
    resetFilters: () => dispatch(clearMilestoneFiltersAction())
});

export default connect(mapStateToProps, mapDispatchToProps)(MilestonesCard);
