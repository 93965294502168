import React, {Component} from 'react';
import {connect} from 'react-redux';
import './_styles.scss';
import Api from "../../../services/ApiService";

class MilestoneDeleteButton extends Component {
    constructor(props) {
        super(props);
        let isNew;
        const {milestone} = this.props;
        if(new Date(milestone.created_at).getTime() > new Date().setDate(new Date().getDate() - 7)) {
            isNew = true;
        } else {
            isNew = false;
        }

        this.state = {
            isNew,
            isPending: false,
            justDeleted: false,
        };
    }

    async toggleDeletion() {
        const {milestone, resetMilestone} = this.props;
        this.setState({
            isPending: true
        });
        if (milestone.deleted_at === null ) {
            try {
                await Api.delete(`milestones/${milestone.id}`);
                this.setState({
                    justDeleted: true,
                });
                const cpMs = milestone;
                cpMs['deleted_at'] = (new Date()).toISOString();
                resetMilestone(cpMs);
            } catch (e) {

            }
            this.setState({
                isPending: false
            });
        } else {
            try {
                await Api.patch(`milestones/${milestone.id}`, {restore: true});
                this.setState({
                    justDeleted: false,
                });
                const cpMs = milestone;
                cpMs['deleted_at'] = null;
                resetMilestone(cpMs);
            } catch (e) {

            }
            this.setState({
                isPending: false
            });
        }
    }

    render() {
        const { isPending, justDeleted, isNew } = this.state;
        const { milestone } = this.props;
        let deletionIcon = <div className="icon-container">
                <i className={'zmdi zmdi-' + (justDeleted || milestone.deleted_at !== null ? 'undo' : 'delete')}/>
            </div>;

        if (isPending) {
            deletionIcon = <div className="icon-container">
                <i className='zmdi zmdi-refresh zmdi-hc-spin'/>
            </div>;
        }

        return <div className={'milestone-delete-button ' + (isPending ? 'is-pending' : '')} onClick={(e) => this.toggleDeletion()}>
            {deletionIcon}
        </div>;
    }
}


const mapStateToProps = state => ({
    user: state.auth.user,
});

export default connect(mapStateToProps)(MilestoneDeleteButton);

