import {
    ACT_AUTH_LOGIN_ATTEMPT, ACT_AUTH_LOGIN_FAILED, ACT_AUTH_LOGIN_SUCCESS, ACT_AUTH_LOGOUT_INIT,
    ACT_AUTH_NO_TOKEN_FOUND,
    ACT_AUTH_TOKEN_FAIL,
    ACT_AUTH_TOKEN_SUCCESS
} from "../constants/actions";

const defaultState = {
    authLoading: true,
    isLoggingIn: false,
    isLoggedIn: false,
    lastAttemptFailed: false,
    user: false,
};

const authReducer = (state= defaultState, action) => {
    switch (action.type) {
        case ACT_AUTH_LOGOUT_INIT:
            return {
                authLoading: true,
                ...state
            };
        case ACT_AUTH_LOGIN_SUCCESS:
            return defaultState;
        case ACT_AUTH_LOGIN_ATTEMPT:
            return {
                authLoading: true,
                isLoggingIn: true,
                isLoggedIn: false,
                lastAttemptFailed: false,
                user: false,
            };
        case ACT_AUTH_TOKEN_SUCCESS:
            return {
                authLoading: false,
                isLoggedIn: true,
                isLoggingIn: false,
                lastAttemptFailed: false,
                user: action.user,
            };
        case ACT_AUTH_LOGIN_FAILED:
            return {
                authLoading: false,
                isLoggedIn: false,
                isLoggingIn: false,
                lastAttemptFailed: true,
                user: false,
            };
        case ACT_AUTH_NO_TOKEN_FOUND:
        case ACT_AUTH_TOKEN_FAIL:
            return {
                authLoading: false,
                isLoggedIn: false,
                isLoggingIn: false,
                lastAttemptFailed: false,
                user: false,
            };
        default:
            return state;
    }
};

export default authReducer;
