import React, {Component} from 'react'
import { connect } from 'react-redux';

import PageLoaderSpinner from "../../atoms/PageLoaderSpinner/PageLoderSpinner";

class FullPageLoader extends Component {
    render()
    {
        const { authLoading } = this.props;
        return authLoading ?
            <div className="page-loader">
                <PageLoaderSpinner/>
            </div>
            : <></>;
    }
}

const mapStateToProps = state => ({
    authLoading: state.auth.authLoading,
});

export default connect(mapStateToProps)(FullPageLoader);
