import React, {Component} from "react";
import VenturesCard from "../../organisms/VenturesCard/VenturesCard";
import UsersCard from "../../organisms/UsersCard/UsersCard";

export default class Users extends Component {
    render() {
        return (
          <div className="row">
              <div className="col-12">
                  <UsersCard />
              </div>
          </div>
        );
    }
}
