import React from 'react';
import {
    Router,
    Switch,
    Route,
    Redirect,
} from "react-router-dom";

import '@icon/material-icons/material-icons.css';
import './scss/app.scss';
import Login from "./components/pages/Login/Login";
import Milestones from "./components/pages/Milestones/Milestones";
import Overview from "./components/pages/Overview/Overview";
import {history} from "./history";
import Categories from "./components/pages/Categories/Categories";
import Ventures from "./components/pages/Ventures/Ventures";
import Users from "./components/pages/Users/Users";
import OnePageLayout from "./components/template/AppLayout/OnePageLayout";

function App() {
  return (
      <Router history={history}>
          <Switch>
              <Route path="/" exact>
                    <Redirect to="/overview" />
              </Route>
              <Route path="/login">
                   <Login />
              </Route>
              <Route path="/">
                  <OnePageLayout>
                      <Route path="/overview" component={Overview} />
                      <Route path="/milestones" component={Milestones} />
                      <Route path="/settings/users" component={Users}/>
                      <Route path="/settings/categories" component={Categories}/>
                      <Route path="/settings/ventures" component={Ventures}/>
                  </OnePageLayout>
              </Route>
          </Switch>
      </Router>
);
}

export default App;
