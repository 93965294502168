import React, {Component} from "react";
import {Modal, OverlayTrigger, Tooltip} from "react-bootstrap";
import Flatpickr from "react-flatpickr";
import {connect} from 'react-redux';
import './_style.scss';
import Select from 'react-select'
import PropTypes from "prop-types";
import {addMilestoneAction} from "../../../actions/milestonesActions";
import {getMilestoneFilterOptions} from "../../../helpers/selectOptions";
import PageLoaderSpinner from "../../atoms/PageLoaderSpinner/PageLoderSpinner";
import {fetchCategoriesAction} from "../../../actions/categoriesActions";
import {fetchUsersAction} from "../../../actions/usersActions";
import {fetchVenturesAction} from "../../../actions/venturesActions";
import {adjustForTimezone} from "../../../helpers/dateFormat";

class AddMilestoneModal extends Component {
    static propTypes = {
        close: PropTypes.func.isRequired,
        show: PropTypes.bool.isRequired,
        didLoadCategories: PropTypes.bool.isRequired,
        didLoadUsers: PropTypes.bool.isRequired,
        didLoadVentures: PropTypes.bool.isRequired,
        categoriesList: PropTypes.array,
        usersList: PropTypes.array,
        venturesList: PropTypes.array,
        addMilestone: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);
        const {
            fetchCategories,
            fetchVentures,
            fetchUsers,
        } = this.props;
        this.state = {
            dueDate: '',
            description: '',
            selectedMilestoneCategory: null,
            selectedVenture: null,
            selectedAssignedTo: null,
            error: '',
            blocked: true,
            blocker: 'Please fill all fields',
        };
        fetchCategories();
        fetchVentures();
        fetchUsers();
    }

    updateBlockers()
    {
        const {
            selectedMilestoneCategory,
            selectedVenture,
            selectedAssignedTo,
            dueDate,
            description,
            error,
        } = this.state;

        if (description.length < 3) {
            this.setState({blocked: true, blocker: 'Description too short'})
        } else if (selectedAssignedTo === null) {
            this.setState({blocked: true, blocker: 'Please select user'})
        } else if (selectedMilestoneCategory === null) {
            this.setState({blocked: true, blocker: 'Please select milestone category'})
        } else if (selectedVenture === null) {
            this.setState({blocked: true, blocker: 'Please select venture'})
        } else if (!dueDate || dueDate.length === 0 || dueDate[0].length < 5) {
            this.setState({blocked: true, blocker: 'Please enter due date'})
        } else {
            this.setState({blocked: false});
        }

    }

    close() {
        this.setState({
            dueDate: '',
            description: '',
            milestoneCategoryId: null,
            ventureId: null,
            assignedTo: null,
        });
        this.props.close();
    }

    save() {
        const {
            selectedMilestoneCategory,
            selectedVenture,
            selectedAssignedTo,
            dueDate,
            description,
        } = this.state;
        this.props.addMilestone(
            selectedVenture.value,
            selectedMilestoneCategory.value,
            selectedAssignedTo.value,
            dueDate[0],
            description,
        );
        this.close();
    }

    inputUpdate(fieldName, value) {
        const newState = {error: ''};
        newState[fieldName] = value;
        this.setState(
            newState,
            () => this.updateBlockers()
        );
    }

    render() {
        const {
            selectedMilestoneCategory,
            selectedVenture,
            selectedAssignedTo,
            dueDate,
            description,
            blocked,
            blocker,
            error,
            isPending,
        } = this.state;
        const {
            show,
            didLoadCategories,
            didLoadVentures,
            didLoadUsers,
            venturesList,
            categoriesList,
            usersList,
        } = this.props;
        const {venturesOptions, categoriesOptions, usersOptions } = getMilestoneFilterOptions(
            didLoadVentures,
            venturesList,
            didLoadCategories,
            categoriesList,
            didLoadUsers,
            usersList,
        );

        let submitButton = blocked
            ? <OverlayTrigger
                placement="bottom"
                delay={{ show: 250, hide: 400 }}
                overlay={
                    <Tooltip id="validation-blocker-tooltip">
                        <div>
                            {blocker}
                        </div>
                    </Tooltip>
                }
            >
                <div>
                    <button onClick={(ev) => ev.preventDefault()}><i className="zwicon-checkmark" /></button>
                </div>
            </OverlayTrigger>
            : <button onClick={() => this.save()}><i className="zwicon-checkmark" /></button>;
        if (isPending) {
            submitButton = <PageLoaderSpinner />
        }

        return (
            <Modal
                dialogClassName="add-milestone-modal"
                show={show}
                onHide={() => this.close()}
                centered
                enforceFocus={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title>NEW MILESTONE</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                        <div className="row">
                            <div className="col-md-6 col-sm-12 remove-padding">
                                <Select
                                    className="react-select-container"
                                    classNamePrefix="react-select"
                                    placeholder="Venture"
                                    options={venturesOptions}
                                    value={selectedVenture}
                                    onChange={(selection) => this.inputUpdate('selectedVenture', selection)}
                                />
                                <Select
                                    className="react-select-container"
                                    classNamePrefix="react-select"
                                    placeholder="Owner"
                                    options={usersOptions}
                                    value={selectedAssignedTo}
                                    onChange={(selection) => this.inputUpdate('selectedAssignedTo', selection)}
                                />
                            </div>
                            <div className="col-md-6 col-sm-12  remove-padding" >
                                <Select
                                    className="react-select-container"
                                    classNamePrefix="react-select"
                                    placeholder="Category"
                                    options={categoriesOptions}
                                    value={selectedMilestoneCategory}
                                    onChange={(selection) => this.inputUpdate('selectedMilestoneCategory', selection)}
                                />
                                <Flatpickr
                                    className="milestone-due-date-picker"
                                    options={{dateFormat: 'd.m.Y'}}
                                    static={true}
                                    placeholder="Due date"
                                    date={dueDate}
                                    onChange={(date) => this.inputUpdate('dueDate', [adjustForTimezone(date)])}
                                />
                            </div>
                        </div>

                    <br />

                    <br />
                    <textarea
                        placeholder="Enter milestone description"
                        onChange={(ev) => this.inputUpdate('description', ev.target.value)}
                    >
                        {description}
                    </textarea>
                    {submitButton}
                </Modal.Body>
            </Modal>
        )
    }
}

const mapPropsToState = state => ({
    didLoadCategories: state.categories.didLoadCategories,
    categoriesList: state.categories.categoriesList,
    didLoadVentures: state.ventures.didLoadVentures,
    venturesList: state.ventures.venturesList,
    didLoadUsers: state.users.didLoadUsers,
    usersList: state.users.usersList,
});

const mapDispatchToProps = dispatch => ({
    fetchCategories:  () => dispatch(fetchCategoriesAction()),
    fetchVentures:  () => dispatch(fetchVenturesAction()),
    fetchUsers:  () => dispatch(fetchUsersAction()),
    addMilestone: (
        ventureId,
        milestoneCategoryId,
        assignedTo,
        dueDate,
        description,
    ) => dispatch(addMilestoneAction(ventureId, milestoneCategoryId, assignedTo, dueDate, description))
});

export default connect(mapPropsToState, mapDispatchToProps)(AddMilestoneModal);
