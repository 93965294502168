import axios from 'axios';
const config = window.config;

export default class Api {
    static async post(url, data = {}, throwErrors = false)
    {
        const accessToken = localStorage.getItem('access_token');
        const axiosConf = {validateStatus: false, headers: {'Authorization': 'Bearer ' + accessToken}};
        try {
            const response =  await axios.post(config.apiUrl + url, data, axiosConf);
            if (throwErrors && (response.status <= 100 || response.status >= 400)) {
                throw new Error('API Returned error status ' + response.status);
            }
            return response;
        } catch (e) {
            Api.showError(e);
        }
    }

    static async delete(url, throwErrors = false)
    {
        const accessToken = localStorage.getItem('access_token');
        const axiosConf = {validateStatus: false, headers: {'Authorization': 'Bearer ' + accessToken}};
        try {
            const response =  await axios.delete(config.apiUrl + url, axiosConf);
            if (throwErrors && (response.status <= 100 || response.status >= 400)) {
                throw new Error('API Returned error status ' + response.status);
            }
            return response;
        } catch (e) {
            Api.showError(e);
        }
    }

    static async patch(url, data = {}, throwErrors = false)
    {
        const accessToken = localStorage.getItem('access_token');
        const axiosConf = {validateStatus: false, headers: {'Authorization': 'Bearer ' + accessToken}};
        try {
            const response =  await axios.patch(config.apiUrl + url, data, axiosConf);
            if (throwErrors && (response.status <= 100 || response.status >= 400)) {
                throw new Error('API Returned error status ' + response.status);
            }
            return response;
        } catch (e) {
            Api.showError(e);
        }
    }

    static async get(url, throwErrors = false) {
        const accessToken = localStorage.getItem('access_token');
        const axiosConf = {validateStatus: false, headers: {'Authorization': 'Bearer ' + accessToken}};
        try {
            const response =  await axios.get(config.apiUrl + url, axiosConf);
            if (throwErrors && (response.status <= 100 || response.status >= 400)) {
                throw new Error('API Returned error status ' + response.status);
            }
            return response;
        } catch (e) {
            Api.showError(e);
        }
    }

    static async uploadOneFile(url, file, throwErrors = false) {
        const accessToken = localStorage.getItem('access_token');
        const axiosConf = {validateStatus: false, headers: {'Authorization': 'Bearer ' + accessToken, 'Content-Type': 'multipart/form-data'}};

        let data = new FormData();
        data.append("file", file);

        try {
            const response =  await axios.post(config.apiUrl + url, data, axiosConf);
            if (throwErrors && (response.status <= 100 || response.status >= 400)) {
                throw new Error('API Returned error status ' + response.status);
            }
            return response;
        } catch (e) {
            Api.showError(e);
        }


    }

    static showError(e)
    {
        alert(e);
        console.log(e);
    }

}
