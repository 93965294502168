import {combineReducers} from "redux";
import authReducer from "./authReducer";
import categoriesReducer from "./categoriesReducer";
import venturesReducer from "./venturesReducer";
import usersReducer from "./usersReducer";
import milestonesReducer from "./milestonesReducer";
import commonReducer from "./commonReducer";

export default combineReducers({
   auth: authReducer,
   categories: categoriesReducer,
   users: usersReducer,
   ventures: venturesReducer,
   milestones: milestonesReducer,
   common: commonReducer,
});
