import React, {Component} from 'react';
import {connect} from 'react-redux';
import QuickAddBox from "../../molecules/QuickAddBox/QuickAddBox";
import {addCategoryAction, fetchCategoriesAction} from "../../../actions/categoriesActions";
import ContentLoader from "../../molecules/ContentLoader/ContentLoader";
import CategoryRow from "../CategoryRow/CategoryRow";
import CategoryItem from "../CategoryItem/CategoryItem";

class CategoriesCard extends Component {

    constructor(props) {
        super(props);
        this.tableRef = React.createRef();
        this.state = {
            fixPlusSign: false,
        };
    }

    componentDidMount() {
        this.props.fetchCategories();
        window.addEventListener('scroll', (ev) => this.handleScroll(ev));
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', (ev) => this.handleScroll(ev));
    }

    handleScroll(ev) {
        if (this.tableRef.current === null) {
            return;
        }
        const {top, bottom} = this.tableRef.current.getBoundingClientRect();
        if (top < 65 && bottom > 150) {
            this.setState({fixPlusSign: true});
        } else {
            this.setState({fixPlusSign: false});
        }
    }

    render() {
        const {fixPlusSign} = this.state;
        const {addCategory, isLoadingCategories, categoriesList} = this.props;
        if (isLoadingCategories) {
            return <ContentLoader />
        }
        const categoriesRows = categoriesList.map((category) => (<CategoryItem key={category.id} category={category} />));
        return (
            <div className="card content-card" ref={this.tableRef}>
                <div className="card-body">
                    <h4 className="card-title">Categories</h4>
                    <h6 className="card-subtitle"> &nbsp; </h6>
                    <div className={fixPlusSign ? 'table-plus-icon fixed-plus': 'table-plus-icon'}>
                        <QuickAddBox placeholder="Category name" action={addCategory}/>
                    </div>
                    <table className="table table-striped mb-0">
                        <thead>
                        <tr>
                            <th>Category Name</th>
                            <th className="text-right">Total milestones</th>
                            <th></th>
                        </tr>
                        </thead>
                        <tbody>
                            { categoriesRows.length ? categoriesRows : <tr><td colSpan="9">--- Categories list is empty ---</td></tr> }
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    categoriesList: state.categories.categoriesList,
    isLoadingCategories: state.categories.isLoadingCategories,
})

const mapDispatchToProps = dispatch => ({
    fetchCategories: () => dispatch(fetchCategoriesAction()),
    addCategory: (categoryName) => dispatch(addCategoryAction(categoryName)),
})

export default connect(mapStateToProps, mapDispatchToProps)(CategoriesCard);
